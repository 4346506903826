import axios from './axios'

export default {
  getTournamentsForCurrentUser(page, perPage, filter, sortBy) {
    let query = `
        query user($page: Int, $perPage: Int, $filter: UserTournamentsPaginationFilter, $sortBy: String) {
          currentUser {
            id
            tournaments(query: {page: $page, perPage: $perPage, filter: $filter, sortBy: $sortBy}) {
              pageInfo {
                totalPages
              }
              nodes {
                id
                name
                isOnline
                city
                countryCode
                startAt
                endAt
                numAttendees
                events {
                  id
                }
                images {
                  url
                  type
                }
              }
            }
          }
        }
          `

    let variables = { page, perPage, filter, sortBy }

    return axios.post('', { query, variables })
  },
  getCurrentUserDetails() {
    let query = `
      query currentUserDetails {
        currentUser {
          player {
            gamerTag
          }
          images(type: "profile") {
            url
          }
        }
      }
          `

    let variables = {}

    return axios.post('', { query, variables })
  },
  async isCurrentUserAdmin(tournamentName, id) {
    /*Really scuffed implementation D:
    but I can't think of any edge cases! :D*/

    let query = `
    query isCurentUserAdmin($name: String) {
      currentUser {
        tournaments(query: {
          filter: {
            search: {
              fieldsToSearch: ["tournament.name"],
              searchString: $name 
            }
            tournamentView: "admin"
          }
        }) {
          nodes {
            id
            admins {
              id
            }
          }
        }
      }
    }
    `

    let variables = { name: tournamentName }

    let { data } = await axios.post('', { query, variables })

    return Boolean(
      data?.data?.currentUser.tournaments.nodes.find((tournament) => tournament.id == id)
    )
  }
}
