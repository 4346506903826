<template>
  <v-app style="min-width: 100vw; overflow: auto" class="font">
    <v-navigation-drawer
      v-model="drawer"
      :temporary="mdAndDown"
      color="secondary"
      class="rounded-e-xl"
      floating
      disable-resize-watcher
      disable-route-watcher
      :touchless="drawerMap[$route.name] == undefined"
    >
      <component :is="drawerMap[$route.name]"></component>
    </v-navigation-drawer>
    <v-app-bar flat color="background">
      <v-btn
        v-if="drawerMap[$route.name] != undefined"
        :icon="drawer ? 'mdi-menu-open' : 'mdi-menu'"
        @click="drawer = !drawer"
      ></v-btn>
      <router-link
        :to="{ name: 'home' }"
        class="pl-2 text-h5 cursor-pointer font-weight-medium text-decoration-none"
        variant="plain"
        style="font-family: Audiowide !important; color: inherit"
      >
        StartKit
      </router-link>
      <div v-if="!smAndDown" class="pl-3 d-flex w-75 align-center">
        <v-text-field
          v-if="searchMap[$route.name]"
          v-model="searchStore.value"
          rounded
          hide-details
          variant="outlined"
          prepend-inner-icon="mdi-magnify"
          density="compact"
          class="px-1"
          :label="searchMap[$route.name]"
        ></v-text-field>
      </div>
      <template #append>
        <v-btn icon>
          <v-img
            width="20"
            :src="`/img/discord-${current.dark ? 'white' : 'black'}.svg`"
            @click="openInNew('https://discord.gg/mzq6PWtARa')"
          ></v-img>
          <v-tooltip activator="parent" location="bottom">Discord</v-tooltip>
        </v-btn>

        <v-menu location="bottom" :close-on-content-click="false">
          <template #activator="{ props }">
            <v-btn id="theme" icon="mdi-palette" v-bind="props" dark> </v-btn>
            <v-tooltip activator="#theme" location="bottom">Theme</v-tooltip>
          </template>

          <pallete-selector></pallete-selector>
        </v-menu>

        <v-menu location="bottom">
          <template #activator="{ props }">
            <v-btn icon v-bind="props">
              <v-avatar
                class="mx-2"
                :icon="currUserStore.details?.images[0]?.url ? null : 'mdi-account'"
              >
                <v-img
                  v-if="currUserStore.details?.images[0]?.url"
                  :src="currUserStore.details?.images[0]?.url"
                  cover
                  width="48"
                  height="48"
                ></v-img>
              </v-avatar>
            </v-btn>
          </template>

          <v-card class="pa-2">
            <div class="mx-auto text-center">
              <div v-if="currUserStore.details">
                <div class="d-flex align-center">
                  <v-avatar
                    class="mx-2"
                    :icon="currUserStore.details?.images[0].url ? null : 'mdi-account'"
                  >
                    <v-img
                      :src="currUserStore.details?.images[0].url"
                      cover
                      width="48"
                      height="48"
                    ></v-img>
                  </v-avatar>
                  <h3>{{ currUserStore.details?.player.gamerTag }}</h3>
                </div>

                <v-divider class="my-1"></v-divider>
                <v-list density="compact" class="pa-0">
                  <v-list-item prepend-icon="mdi-logout" @click="logout"> Logout </v-list-item>
                  <v-divider class="my-1"></v-divider>
                  <v-list-item prepend-icon="mdi-shield" to="/privacy-policy">
                    Privacy
                  </v-list-item>
                  <v-divider class="my-1"></v-divider>
                  <v-list-item
                    class="text-right"
                    @click="openInNew('https://twitter.com/CloakedMk')"
                  >
                    <template #prepend>
                      <v-img
                        width="20"
                        :src="`/img/x-${current.dark ? 'white' : 'black'}.svg`"
                      ></v-img>
                    </template>
                    Twitter
                  </v-list-item>
                  <v-divider class="my-1"></v-divider>
                  <v-list-item class="text-right" @click="openInNew('https://ko-fi.com/cloaked')">
                    <template #prepend>
                      <v-img width="24" src="/img/ko-fi.png"></v-img>
                    </template>
                    Tips!
                  </v-list-item>
                </v-list>
              </div>
            </div>
          </v-card>
        </v-menu>
      </template>
      <template v-if="smAndDown && searchMap[$route.name]" #extension>
        <v-text-field
          v-model="searchStore.value"
          rounded
          hide-details
          variant="outlined"
          prepend-inner-icon="mdi-magnify"
          :label="searchMap[$route.name]"
          density="compact"
          class="mx-2 mt-n4"
        ></v-text-field>
      </template>
    </v-app-bar>
    <v-snackbar v-model="notification.visible" :color="notification.color">{{
      notification.text
    }}</v-snackbar>
    <v-main>
      <v-container fluid style="overflow-y: auto">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import { useDisplay, useTheme } from 'vuetify'
import { useCurrUserStore } from './stores/currentUser'
import EventNavigation from './components/EventNavigation.vue'
import PalleteSelector from './components/PalleteSelector.vue'
import { useSearchStore } from './stores/search'
import { markRaw } from 'vue'
import { useNotificationStore } from './stores/notification'

export default {
  components: { EventNavigation, PalleteSelector },
  setup() {
    let { smAndDown, mdAndDown } = useDisplay()
    let searchStore = useSearchStore()
    let currUserStore = useCurrUserStore()
    let notification = useNotificationStore()
    let { current } = useTheme()

    return { smAndDown, mdAndDown, searchStore, currUserStore, current, notification }
  },
  data: () => {
    return {
      drawer: false,
      eventNavComponent: markRaw(EventNavigation),
      searchMap: {
        reporting: 'Search Sets',
        tournaments: 'Search Tournaments'
      }
    }
  },
  computed: {
    drawerMap() {
      return { seeding: this.eventNavComponent, reporting: this.eventNavComponent }
    }
  },
  watch: {
    mdAndDown() {
      this.checkDrawerVisability()
    },
    '$route.name'() {
      this.checkDrawerVisability()
    }
  },
  mounted() {
    this.drawer = this.checkDrawerVisability()
    if (this.$cookies.get('access_token') && !window.location.href.includes('oauth')) {
      this.currUserStore.fetchDetails()
    }
  },
  methods: {
    checkDrawerVisability() {
      this.drawer = !this.mdAndDown && this.drawerMap[this.$route.name] != undefined
    },
    logout() {
      this.$cookies.remove('access_token')
      this.$router.push('/about')
      this.setTimeout(() => {
        this.$router.go()
      }, 200)
    },
    updateTracking(allowTracking) {
      localStorage.setItem('allowTracking', allowTracking)
      if (allowTracking) {
        this.showHeart = true
        setInterval(() => {
          location.reload()
        }, 500)
      } else {
        this.showTrackingInfo = false
      }
    },
    openInNew(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
<style>
@font-face {
  font-family: Audiowide;
  src: url(/fonts/Audiowide-Regular.ttf);
}

.icon {
  height: 48px;
  width: 48px;
  border-radius: 8px;
}
</style>
<style lang="scss">
@import './scss/variables.scss';

.v-application {
  [class*='text-'] {
    font-family: $font-family, sans-serif !important;
  }
  font-family: $font-family, sans-serif !important;
}
</style>
