<template>
  <div>
    <v-card
      :loading="loading"
      :variant="color != 'surface' ? 'tonal' : 'elevated'"
      :color="color"
      elevation="1"
    >
      <v-card-text class="pb-0">
        <p class="text-center text-overline text-truncate" style="line-height: 1rem">
          {{ set.phaseGroup.phase.name }}
        </p>
        <p class="text-center text-overline text-truncate" style="line-height: 1rem">
          Pool {{ set.phaseGroup.displayIdentifier }}
        </p>
        <p class="text-center text-overline text-truncate" style="line-height: 1rem">
          {{ set.fullRoundText }} - {{ set.identifier }}
        </p>
        <SetDisplay :set="set"></SetDisplay>
        <p
          v-if="[2, 6].includes(set.state)"
          class="text-center text-overline"
          style="line-height: 1rem"
        >
          {{ displayTime }}
        </p>
        <div v-else style="height: 1rem"></div>
      </v-card-text>
      <v-card-actions class="pa-0">
        <div v-if="set.state !== 3">
          <v-item-group v-model="statusToggle" :selected-class="`bg-${color}`">
            <v-item v-slot="{ selectedClass }">
              <v-btn
                :class="['ma-0', selectedClass]"
                size="small"
                height="40"
                variant="text"
                min-width="40"
                width="40"
                @click="callSet()"
              >
                <v-icon size="x-large">mdi-bell</v-icon>
                <v-tooltip activator="parent">Call</v-tooltip>
              </v-btn>
            </v-item>
            <v-item v-slot="{ selectedClass }">
              <v-btn
                :class="['ma-0', selectedClass]"
                size="small"
                height="40"
                variant="text"
                min-width="40"
                width="40"
                @click="startSet()"
              >
                <v-icon size="x-large">mdi-play</v-icon>
                <v-tooltip activator="parent">Start</v-tooltip>
              </v-btn>
            </v-item>
          </v-item-group>
        </div>
        <div v-if="set.state !== 1">
          <v-dialog width="500">
            <template #activator="{ props }">
              <v-btn
                size="small"
                height="40"
                variant="text"
                min-width="40"
                width="40"
                v-bind="props"
              >
                <v-icon size="x-large">mdi-refresh</v-icon>
                <v-tooltip activator="parent">Reset</v-tooltip>
              </v-btn>
            </template>

            <template #default="{ isActive }">
              <v-card :title="`Reset Set - ${set.identifier}`">
                <v-card-text>
                  <v-alert
                    v-if="set.resetAffectedData.affectedSetCount > 0"
                    color="warning"
                    icon="mdi-alert"
                  >
                    This will also reset
                    <b> {{ set.resetAffectedData.affectedSetCount }} </b> dependant sets
                  </v-alert>
                  Are you sure you want to reset this match? This will remove any existing
                  game/character/winner data.
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn text="Cancel" @click="isActive.value = false"></v-btn>
                  <v-btn variant="flat" color="accent" @click="resetSet(isActive)">Reset</v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </div>
        <v-spacer />
        <v-btn size="small" height="40" variant="text" min-width="32" width="32" @click="openSet">
          <v-icon size="x-large">mdi-open-in-new</v-icon>
          <v-tooltip activator="parent">Open in start.gg</v-tooltip>
        </v-btn>
        <v-menu v-model="quick.display" :close-on-content-click="false" location="bottom center">
          <template #activator="{ props }">
            <v-btn height="40" variant="text" min-width="32" width="32" v-bind="props">
              <v-icon>mdi-flash</v-icon>
              <v-tooltip activator="parent">Quick Report</v-tooltip>
            </v-btn>
          </template>

          <v-card width="250px" max-width="250px">
            <div class="d-flex align-center flex-column ma-1">
              <p class="text-center text-truncate">{{ set.slots[0].entrant.name }}</p>
              <div v-for="i in ['0', '1']" :key="i" class="d-flex">
                <v-btn
                  variant="flat"
                  border
                  min-width="0"
                  width="16"
                  :color="quick.score[i] == -1 ? 'warning' : ''"
                  :active="quick.score[i] == -1"
                  @click="setDq(i)"
                >
                  DQ
                </v-btn>
                <v-btn-toggle
                  v-model="quick.score[i]"
                  variant="flat"
                  divided
                  mandatory
                  density="compact"
                  class="mx-1"
                  :color="quick.score[i] < quick.score[1 - i] ? 'error' : 'success'"
                >
                  <v-btn
                    v-for="item in ['0', '1', '2', '3', '4']"
                    :key="item"
                    min-width="32"
                    width="32"
                    border
                    size="x-small"
                    @click="updateWinner()"
                  >
                    {{ item }}
                  </v-btn>
                </v-btn-toggle>
                <v-btn
                  variant="flat"
                  border
                  min-width="0"
                  width="16"
                  :color="quick.winner == i ? 'success' : ''"
                  :active="quick.winner == i"
                  @click="setW(i)"
                >
                  W
                </v-btn>
              </div>
              <p class="text-center text-truncate">{{ set.slots[1].entrant.name }}</p>
            </div>
            <div class="d-flex pa-1 pt-0">
              <!-- <v-btn variant="text" border @click="cancelQuickReport"> Cancel </v-btn>
            <v-spacer /> -->
              <v-btn
                color="accent"
                block
                :disabled="quick.winner === null"
                variant="text"
                border
                prepend-icon="mdi-send"
                @click="submitQuickReport"
              >
                Submit
              </v-btn>
            </div>
          </v-card>
        </v-menu>
        <v-btn
          height="40"
          icon
          min-width="40"
          width="40"
          :class="{ toggleUpDown: true, rotate: detailed.display }"
          @click="expandCard"
        >
          <v-tooltip activator="parent">Add Details</v-tooltip>
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="detailed.display">
          <v-divider></v-divider>
          <v-card-actions class="d-flex flex-column pa-0">
            <div class="d-flex justify-space-between w-100 px-2">
              <div class="text-overline text-truncate" style="max-width: 50%">
                {{ set.slots[0].entrant?.name }}
              </div>
              <div class="text-overline text-truncate" style="max-width: 50%">
                {{ set.slots[1].entrant?.name }}
              </div>
            </div>
            <v-btn
              prepend-icon="mdi-swap-horizontal"
              size="small"
              border
              class="mb-1"
              @click="swapDetailed"
              >Swap results</v-btn
            >
            <div class="d-flex flex-column w-100 px-2">
              <v-expand-transition
                v-for="game in detailed.gameData"
                :key="`${set.id} - ${game.gameNum}`"
                appear
              >
                <div class="d-flex flex-column">
                  <v-divider class="py-1"></v-divider>
                  <div class="d-flex mb-1 justify-space-between align-center">
                    <div class="d-flex align-center">
                      <div v-if="showCharacters" :id="`player1Characters-${game.gameNum}`">
                        <v-dialog v-if="smAndDown" v-model="game.selecting[0]">
                          <template #activator="{ props }">
                            <v-btn
                              border
                              flat
                              class="mr-1"
                              min-width="36"
                              width="36"
                              v-bind="props"
                              @select="(character) => (game.characters[0] = character)"
                              ><v-img
                                v-if="game.characters[0]"
                                :src="game.characters[0].img"
                                width="24"
                                height="24"
                              ></v-img>
                              <v-icon v-else>mdi-account</v-icon>
                            </v-btn>
                          </template>

                          <v-card height="400"
                            ><CharacterSelect
                              @select="(character) => selectCharacter(character, game, 0)"
                          /></v-card>
                        </v-dialog>
                        <v-menu
                          v-else
                          v-model="game.selecting[0]"
                          :close-on-content-click="false"
                          location="bottom"
                        >
                          <template #activator="{ props }">
                            <v-btn border flat class="mr-1" min-width="36" width="36" v-bind="props"
                              ><v-img
                                v-if="game.characters[0]"
                                :src="game.characters[0].img"
                                width="24"
                                height="24"
                              ></v-img>
                              <v-icon v-else>mdi-account</v-icon>
                            </v-btn>
                          </template>
                          <v-card width="400"
                            ><CharacterSelect
                              @select="(character) => selectCharacter(character, game, 0)"
                          /></v-card>
                        </v-menu>
                      </div>

                      <v-btn
                        border
                        flat
                        :variant="getGameColor(game, 0) ? 'flat' : null"
                        min-width="36"
                        width="36"
                        :color="getGameColor(game, 0)"
                        @click="setDetailedW(game, 0)"
                        >W</v-btn
                      >
                    </div>
                    <p>Game {{ game.gameNum }}</p>
                    <div class="d-flex align-center">
                      <v-btn
                        border
                        :variant="getGameColor(game, 1) ? 'flat' : null"
                        class="mr-1"
                        min-width="36"
                        width="36"
                        :color="getGameColor(game, 1)"
                        @click="setDetailedW(game, 1)"
                        >W</v-btn
                      >
                      <div v-if="showCharacters" :id="`player2Characters-${game.gameNum}`">
                        <v-dialog v-if="smAndDown" v-model="game.selecting[1]">
                          <template #activator="{ props }">
                            <v-btn
                              border
                              flat
                              min-width="36"
                              width="36"
                              v-bind="props"
                              @select="(character) => selectCharacter(character, game, 1)"
                              ><v-img
                                v-if="game.characters[1]"
                                :src="game.characters[1].img"
                                width="24"
                                height="24"
                              ></v-img>
                              <v-icon v-else>mdi-account</v-icon>
                            </v-btn>
                          </template>

                          <v-card height="400"
                            ><CharacterSelect
                              @select="(character) => selectCharacter(character, game, 1)"
                          /></v-card>
                        </v-dialog>
                        <v-menu
                          v-else
                          v-model="game.selecting[1]"
                          :close-on-content-click="false"
                          location="bottom"
                        >
                          <template #activator="{ props }">
                            <v-btn border flat min-width="36" width="36" v-bind="props"
                              ><v-img
                                v-if="game.characters[1]"
                                :src="game.characters[1].img"
                                width="24"
                                height="24"
                              ></v-img>
                              <v-icon v-else>mdi-account</v-icon>
                            </v-btn>
                          </template>
                          <v-card width="400"
                            ><CharacterSelect
                              @select="(character) => selectCharacter(character, game, 1)"
                          /></v-card>
                        </v-menu>
                      </div>
                    </div>
                  </div>
                  <div v-if="showStages" :id="`stageSelect-${game.gameNum}`">
                    <v-dialog v-if="smAndDown" v-model="game.selectStage">
                      <template #activator="{ props }">
                        <v-btn flat block border class="mb-1" v-bind="props">{{
                          game.stage ? game.stage.name : 'Select Stage'
                        }}</v-btn>
                      </template>

                      <v-card height="400"
                        ><StageSelect @select="(stage) => selectStage(stage, game)"
                      /></v-card>
                    </v-dialog>
                    <v-menu
                      v-else
                      v-model="game.selectStage"
                      :close-on-content-click="false"
                      location="bottom"
                    >
                      <template #activator="{ props }">
                        <v-btn flat block border class="mb-1" v-bind="props">{{
                          game.stage ? game.stage.name : 'Select Stage'
                        }}</v-btn>
                      </template>
                      <v-card width="400" height="400"
                        ><StageSelect @select="(stage) => selectStage(stage, game)"
                      /></v-card>
                    </v-menu>
                  </div>
                </div>
              </v-expand-transition>
            </div>
            <div class="d-flex justify-space-between w-100 px-2">
              <v-btn variant="flat" color="success" border style="width: 45%" @click="newGame">
                <v-icon>mdi-plus</v-icon>
                <v-tooltip activator="parent">Add Game</v-tooltip>
              </v-btn>

              <v-btn variant="flat" color="error" border style="width: 45%" @click="deleteGame">
                <v-icon>mdi-delete</v-icon>
                <v-tooltip activator="parent">Delete Game</v-tooltip>
              </v-btn>
            </div>
            <div class="d-flex pa-2 w-100">
              <v-btn
                flat
                border
                :disabled="detailed.disableSave || disableQuickReport"
                style="width: 45%"
                prepend-icon="mdi-floppy"
                @click="saveDetailed"
                >Save</v-btn
              >
              <v-spacer></v-spacer>
              <v-btn
                flat
                border
                :disabled="!detailed.winnerId"
                style="width: 45%"
                color="accent"
                prepend-icon="mdi-send"
                @click="submitDetailed"
                >Submit</v-btn
              >
            </div>
          </v-card-actions>
        </div>
      </v-expand-transition>
    </v-card>
    <v-dialog v-model="showSwapDialog" width="500px">
      <v-card title="Replace Winner?">
        <v-card-text>
          <v-alert color="warning" icon="mdi-alert"
            >This feature is <b>EXPERIMENTAL</b> and hasn't been stress tested. Please report any
            issues!</v-alert
          >
          Replace winner of <b>{{ set.identifier }}</b
          >? This will swap the winner and loser in all future sets
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn @click="cancelReplace">Cancel</v-btn>
          <v-btn variant="flat" color="accent" @click="replaceWinner">Replace</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SetDisplay from './SetDisplay.vue'
import CharacterSelect from './CharacterSelect.vue'
import StageSelect from './StageSelect.vue'
import setApi from '../api/set.js'
import { useDisplay } from 'vuetify'
import { useEventStore } from '../stores/event'
import { useTournamentStore } from '../stores/tournament'
import { usePlausible } from '../composables/plausible'
import { useNotificationStore } from '../stores/notification.js'

export default {
  components: { SetDisplay, CharacterSelect, StageSelect },
  props: {
    set: {
      type: Object,
      required: true
    }
  },
  setup() {
    let eventStore = useEventStore()
    let tournamentStore = useTournamentStore()
    const { smAndDown } = useDisplay()
    let { trackEvent } = usePlausible()
    let notification = useNotificationStore()

    return { eventStore, smAndDown, tournamentStore, trackEvent, notification }
  },
  data() {
    return {
      quick: {
        score: [0, 0],
        display: false,
        winner: null
      },
      detailed: {
        gameData: [
          {
            gameNum: 1,
            winnerId: null,
            selecting: [false, false],
            selectStage: false,
            characters: [null, null],
            stage: null,
            points: 0
          }
        ],
        display: false,
        winnerId: null,
        disableSave: true
      },
      showSwapDialog: false,
      currGameData: null,
      entrants: [null, null],
      statusToggle: null,
      colorMap: {
        1: 'surface', // Not Started
        2: 'success', // In Progress
        3: 'surface', // Completed
        6: 'warning' // called
      },
      statusMap: {
        2: 1, // In Progress
        6: 0 // called
      },
      loading: false,
      time: 0,
      interval: null,
      displayTime: null
    }
  },
  computed: {
    color() {
      return this.colorMap[this.set.state]
    },
    showCharacters() {
      return this.eventStore.game?.characters
    },
    showStages() {
      return this.eventStore.game?.stages
    },
    disableQuickReport() {
      return (
        this.set.slots[0].entrant == null ||
        this.set.slots[1].entrant == null ||
        this.set.state == 3
      )
    }
  },
  watch: {
    set() {
      this.refreshDetailed()
      this.statusToggle = this.statusMap[this.set.state]
      this.startTimer()
    },
    'eventStore.game'() {
      this.refreshDetailed()
    },
    quick: {
      deep: true,
      handler() {
        this.eventStore.pauseRefresh()
      }
    },
    detailed: {
      deep: true,
      handler() {
        this.eventStore.pauseRefresh()
      }
    }
  },
  beforeMount() {
    this.startTimer()
    this.entrants = [this.set.slots[0].entrant?.id, this.set.slots[1].entrant?.id]
    if (this.eventStore.setsState[this.set.id]) {
      this.quick = this.eventStore.setsState[this.set.id].quick
      this.detailed = this.eventStore.setsState[this.set.id].detailed
    }
    this.eventStore.setsState[this.set.id] = { quick: this.quick, detailed: this.detailed }
    this.refreshDetailed()
  },
  mounted() {
    this.statusToggle = this.statusMap[this.set.state]
    this.updateDetailedWinner()
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  methods: {
    startSet() {
      this.apiCall((id) => setApi.startSet(id))
    },
    callSet() {
      this.apiCall((id) => setApi.callSet(id))
    },
    submitQuickReport() {
      let isDq = this.quick.score[0] === -1 || this.quick.score[1] === -1
      let results = {}
      results[this.set.slots[0].entrant.id] = this.quick.score[0]
      results[this.set.slots[1].entrant.id] = this.quick.score[1]

      let gameData = []

      if (!isDq) {
        Object.keys(results).forEach((playerId) => {
          for (let i = 0; i < results[playerId]; i++) {
            gameData.push({
              winnerId: playerId,
              gameNum: gameData.length + 1
            })
          }
        })
      }
      this.trackEvent('setReported')
      if (!this.set.winnerId) {
        this.apiCall((id) =>
          setApi.detailedReport(id, this.set.slots[this.quick.winner].entrant.id, gameData, isDq)
        ).then(() => {
          this.cancelQuickReport()
        })
      } else if (this.set.winnerId == this.set.slots[this.quick.winner].entrant.id) {
        this.apiCall((id) =>
          setApi.detailedUpdate(id, this.set.slots[this.quick.winner].entrant.id, gameData, isDq)
        ).then(() => {
          this.cancelQuickReport()
        })
      } else {
        this.currSetData = {
          setId: this.set.id,
          gameData,
          winnerId: this.set.slots[this.quick.winner].entrant.id,
          isDq: isDq
        }
        this.showSwapDialog = true
      }
      this.quick.display = false
    },
    cancelQuickReport() {
      this.quick.score = [0, 0]
      this.quick.display = false
      this.quick.winner = null
    },
    resetSet(isActive) {
      isActive.value = false
      this.apiCall(() => setApi.resetSet(this.set.id, true)).then(() => {
        if (!this.detailed.gameData?.length) {
          this.newGame()
        }
      })
    },
    setDq(i) {
      this.quick.score = [0, 0]
      this.quick.score[i] = -1
      this.quick.winner = 1 - i
    },
    setW(i) {
      if (this.quick.score[i] <= this.quick.score[1 - i]) {
        this.quick.score[1 - i] = 0
      }
      if (this.quick.score[i] == -1) {
        this.quick.score[i] = 0
      }
      this.quick.winner = i
    },
    updateWinner() {
      if (this.quick.score[0] == this.quick.score[1]) {
        this.quick.winner = null
        return
      }
      this.quick.winner = this.quick.score[0] > this.quick.score[1] ? 0 : 1
    },
    async apiCall(request) {
      this.loading = true
      let id = this.set.id
      if (this.set.id.includes?.('preview')) {
        try {
          id = await this.eventStore.upgradePreview(this.set.id)
        } catch {
          this.loading = false
          this.notification.showNotification(
            'Error starting phase >.<, try reporting the first set on start.gg',
            'error'
          )
          return
        }
      }
      return request(id)
        .then((res) => {
          if (res.data?.errors) {
            this.notification.showNotification('Error updating set! D:', 'error')
            return
          }
          this.upsertData(res)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    upsertData(res) {
      let key = Object.keys(res.data.data)[0]
      this.loading = false
      if (key === undefined) {
        return
      }
      if (Array.isArray(res.data.data[key])) {
        res.data.data[key].forEach((set) => {
          this.eventStore.upsertSet(set)
        })
      } else {
        this.eventStore.upsertSet(res.data.data[key])
      }
      this.eventStore.sortSets()
    },
    startTimer() {
      if (!this.interval == null) {
        return
      }
      if ([2, 6].includes(this.set.state)) {
        clearInterval(this.interval)
        this.time = Math.floor(new Date() / 1000) - this.set.startedAt
        this.updateDisplayTime()
        this.interval = setInterval(() => {
          this.time++
          this.updateDisplayTime()
        }, 1000)
      } else {
        clearInterval(this.interval)
        this.time = 0
        this.updateDisplayTime()
      }
    },
    updateDisplayTime() {
      let minutes = Math.floor(this.time / 60)
      let seconds = this.time - minutes * 60
      this.displayTime =
        this.str_pad_left(minutes, '0', 2) + ':' + this.str_pad_left(seconds, '0', 2)
    },
    str_pad_left(string, pad, length) {
      return (new Array(length + 1).join(pad) + string).slice(-length)
    },
    getGameColor(game, playerNum) {
      if (!game.winnerId) {
        return null
      }
      return game.winnerId == this.set.slots[playerNum].entrant.id ? 'success' : 'error'
    },
    newGame() {
      this.detailed.gameData.push({
        gameNum: this.detailed.gameData.length + 1,
        winnerId: null,
        selecting: [false, false],
        selectStage: false,
        characters: [
          this.detailed.gameData[this.detailed.gameData.length - 1]?.characters[0],
          this.detailed.gameData[this.detailed.gameData.length - 1]?.characters[1]
        ],
        stage: this.detailed.gameData[this.detailed.gameData.length - 1]?.stage,
        points: 0
      })
      this.updateDetailedWinner()
    },
    deleteGame() {
      this.detailed.gameData.pop()
      this.updateDetailedWinner()
    },
    expandCard() {
      this.detailed.display = !this.detailed.display
    },
    selectCharacter(character, game, playerNum) {
      game.characters[playerNum] = character
      game.selecting[playerNum] = false
      this.detailed.gameData.forEach((curr) => {
        if (curr.gameNum > game.gameNum && curr.characters[playerNum] == null) {
          curr.characters[playerNum] = character
        }
      })
    },
    selectStage(stage, game) {
      game.stage = stage
      game.selectStage = false
      this.detailed.gameData.forEach((curr) => {
        if (curr.gameNum > game.gameNum && curr.stage == null) {
          curr.stage = stage
        }
      })
    },
    setDetailedW(game, playerNum) {
      if (game.winnerId == this.set.slots[playerNum].entrant.id) {
        game.winnerId = null
      } else {
        game.winnerId = this.set.slots[playerNum].entrant.id
      }
      this.updateDetailedWinner()
    },
    updateDetailedWinner() {
      let simple = {}
      this.detailed.disableSave = false
      simple[this.entrants[0]] = 0
      simple[this.entrants[1]] = 0
      this.detailed.gameData.forEach((game) => {
        if (!game.winnerId) {
          this.detailed.disableSave = true
        }
        simple[game.winnerId]++
      })

      if (simple[this.entrants[0]] == simple[this.entrants[1]] || this.detailed.disableSave) {
        this.detailed.winnerId = null
      } else {
        this.detailed.winnerId =
          simple[this.entrants[0]] > simple[this.entrants[1]] ? this.entrants[0] : this.entrants[1]
      }
    },
    swapDetailed() {
      this.detailed.gameData.forEach((game) => {
        let newWinner = this.set.slots.filter((slot) => game.winnerId != slot.entrant.id)[0].entrant
          .id
        game.winnerId = newWinner
        game.characters = [game.characters[1], game.characters[0]]
      })
      this.updateDetailedWinner()
    },
    createGameData() {
      let gameData = []
      this.detailed.gameData.forEach((game) => {
        gameData.push({
          winnerId: game.winnerId,
          gameNum: game.gameNum,
          entrant1Score: null,
          entrant2Score: null,
          stageId: game.stage?.id,
          selections: [
            { entrantId: this.entrants[0], characterId: game.characters[0]?.id },
            { entrantId: this.entrants[1], characterId: game.characters[1]?.id }
          ]
        })
      })
      return gameData
    },
    submitDetailed() {
      this.trackEvent('setReported')
      if (this.set.winnerId == null) {
        this.apiCall((id) =>
          setApi.detailedReport(id, this.detailed.winnerId, this.createGameData())
        ).then(() => {
          this.detailed.display = false
        })
      } else if (this.set.winnerId == this.detailed.winnerId) {
        this.apiCall(() =>
          setApi.detailedUpdate(this.set.id, this.detailed.winnerId, this.createGameData())
        ).then(() => {
          this.detailed.display = false
        })
      } else {
        this.currSetData = {
          setId: this.set.id,
          gameData: this.createGameData(),
          winnerId: this.detailed.winnerId,
          isDq: false
        }
        this.showSwapDialog = true
      }
    },
    saveDetailed() {
      this.apiCall((id) => setApi.detailedSave(id, this.createGameData()))
    },
    refreshDetailed() {
      if (!(this.set.games?.length > this.detailed.gameData.length) && this.set.winnerId == null) {
        // If the server sets are longer than the client sets assume something has been updated and refresh
        return
      }
      this.detailed.gameData = []
      this.set.games?.forEach((game) => {
        let selections = game.selections?.map(
          (selection) =>
            this.eventStore.game?.characters?.filter(
              (character) => character.id == selection.character.id
            )[0]
        )

        if (selections && game.selections[0].entrant.id != this.set.slots[0].entrant.id) {
          selections.reverse()
        }

        this.detailed.gameData.push({
          winnerId: game.winnerId,
          gameNum: game.orderNum,
          stage: this.eventStore.game?.stages?.filter((stage) => stage.id == game.stage?.id)[0],
          characters: selections ?? [null, null],
          selecting: [false, false],
          selectStage: false
        })
      })
    },
    openSet() {
      window.open(
        `https://start.gg/${this.eventStore.eventDetails.slug}/set/${this.set.id}/report`,
        '_blank'
      )
    },
    async replaceWinner() {
      this.loading = true
      this.showSwapDialog = false
      let oldWinner = this.set.winnerId
      let newWinner = this.set.slots.filter((set) => set.entrant.id !== oldWinner)[0].entrant.id
      let resetData = await setApi.getResetAffectedData(this.set.id)
      let reportList = []

      if (this.currSetData == null) {
        //abort!!
        this.loading = false
        return
      }

      reportList.push(this.currSetData)

      resetData.data.data.set.resetAffectedData.affectedSets.forEach((set) => {
        if (set.id == this.set.id) {
          return
        }

        let isDq = false

        let setWinnerId = set.winnerId
        if (setWinnerId == oldWinner) {
          setWinnerId = newWinner
        } else if (setWinnerId == newWinner) {
          setWinnerId = oldWinner
        }

        let gameData = []
        if (set.games == null) {
          //generate game data based on the score
          set.slots.forEach((slot) => {
            let playerId = slot.entrant.id
            if (playerId == oldWinner) {
              playerId = newWinner
            } else if (playerId == newWinner) {
              playerId = oldWinner
            }
            if (slot.standing.stats.score.value == -1) {
              isDq = true
            } else {
              for (let i = 0; i < slot.standing.stats.score.value; i++) {
                gameData.push({
                  winnerId: playerId,
                  gameNum: gameData.length + 1
                })
              }
            }
          })
        } else {
          //transform Game to BracketSetGameDataInput
          set.games.forEach((game) => {
            let gameWinnerId = game.winnerId
            if (gameWinnerId == oldWinner) {
              gameWinnerId = newWinner
            } else if (gameWinnerId == newWinner) {
              gameWinnerId = oldWinner
            }

            let newSelections = []

            game.selections?.forEach((selection) => {
              let selectionEntrant = selection.entrant.id
              if (selection.entrant.id == oldWinner) {
                selectionEntrant = newWinner
              } else if (selection.entrant.id == newWinner) {
                selectionEntrant = oldWinner
              }

              newSelections.push({
                entrantId: selectionEntrant,
                characterId: selection.character?.id
              })
            })

            gameData.push({
              winnerId: gameWinnerId,
              gameNum: game.orderNum,
              entrant1Score: game.entrant1Score,
              entrant2Score: game.entrant2Score,
              stageId: game.stage?.id,
              selections: newSelections
            })
          })
        }
        reportList.push({ setId: set.id, gameData, winnerId: setWinnerId, isDq: isDq })
      })

      let res

      try {
        res = await setApi.reReportMultiple(this.set.id, reportList)
      } catch (err) {
        console.log(err)
      }

      let newSets = {}

      reportList.forEach((result) => {
        this.loading = false
        let key = `set_${result.setId}`

        if (Array.isArray(res.data.data[key])) {
          res.data.data[key].forEach((set) => {
            newSets[set.id] = set
          })
        } else {
          newSets[res.data.data[key].id] = res.data.data[key]
        }

        Object.values(newSets).forEach((set) => {
          this.eventStore.upsertSet(set)
        })

        this.eventStore.sortSets()
      })

      this.loading = false
      this.detailed.display = false
      this.quick.display = false
    },
    cancelReplace() {
      this.currSetData = null
      this.showSwapDialog = false
    }
  }
}
</script>
<style>
.toggleUpDown {
  transition: transform 0.3s ease-in-out !important;
}

.toggleUpDown.rotate {
  transform: rotate(180deg);
}

.playerReport {
  height: 24px;
  border: 1px lightgrey solid;
  width: 40%;
}
</style>
