<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <v-infinite-scroll
    height="100%"
    width="100%"
    :onLoad="currUser.fetchTournaments"
    style="overflow: hidden"
  >
    <template #default>
      <TournamentCardCollection :tournaments="currUser.tournaments"></TournamentCardCollection>
    </template>
    <template #empty>
      <div class="align-center d-flex flex-column">
        <div class="text-h4">No more results</div>
        <!-- <v-img width="256" height="256" src="/img/sleeping.png"></v-img> -->
      </div>
    </template>
    <template #loading>
      <div class="align-center d-flex flex-column">
        <v-row style="width: 100vw">
          <v-col v-for="col in colMap[name]" :key="col" cols="12" sm="12" md="6" lg="4" xl="3">
            <v-skeleton-loader
              v-if="!smAndDown"
              loading
              type="image, heading, text@2"
            ></v-skeleton-loader>
            <v-skeleton-loader v-else type="list-item-avatar-three-line"></v-skeleton-loader>
          </v-col>
        </v-row>
        <!-- <v-img width="256" height="256" src="/img/searching.png"></v-img> -->
      </div>
    </template>
  </v-infinite-scroll>
</template>
<script>
import { useDisplay } from 'vuetify'
import { useCurrUserStore } from '../stores/currentUser'
import TournamentCardCollection from './TournamentCardCollection.vue'

export default {
  components: { TournamentCardCollection },
  setup() {
    let { smAndDown, name } = useDisplay()
    let currUser = useCurrUserStore()

    return { smAndDown, currUser, name }
  },
  data: function () {
    return {
      colMap: {
        xs: 2,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 4
      }
    }
  }
}
</script>
<style></style>
