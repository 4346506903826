<template>
  <div :class="{ 'd-flex': true, 'flex-column': true }">
    <!-- <v-autocomplete
      v-model="filterStore.events"
      :items="events"
      chips
      variant="outlined"
      closable-chips
      item-title="name"
      item-value="id"
      label="Select Events"
      density="compact"
      hide-details
      multiple
      class="overflow-y-hidden pt-2 mb-1"
    ></v-autocomplete> -->
    <div>
      <v-autocomplete
        v-model="eventStore.setFilters.phaseGroupIds"
        :items="phaseGroupItems"
        chips
        variant="outlined"
        closable-chips
        item-props
        label="Select Phases"
        density="compact"
        hide-details
        multiple
        class="overflow-y-hidden pt-2"
        ><template #item="{ item, props }">
          <VDivider v-if="typeof item.raw === 'object' && 'divider' in item.raw" />
          <VListSubheader
            v-else-if="typeof item.raw === 'object' && 'header' in item.raw"
            :title="item.raw.header"
          />
          <VListItem v-else v-bind="props" :label="item.title" :value="item.value">
            <template #prepend="status">
              <VCheckboxBtn
                :key="item.value"
                :model-value="status.isSelected"
                :ripple="false"
                tabindex="-1"
              />
            </template>
          </VListItem>
        </template>
        <template #chip="{ props, item }">
          <v-chip
            v-bind="props"
            :text="`${item.raw.phase} - ${item.raw.title}`"
            color="primary"
          ></v-chip>
        </template>
      </v-autocomplete>
      <v-chip-group v-model="eventStore.setFilters.state" column multiple>
        <v-chip
          v-for="state in states"
          :key="state.value"
          filter
          size="large"
          variant="tonal"
          :color="state.color"
          :value="state.value"
        >
          {{ state.label }}
        </v-chip>
      </v-chip-group>
    </div>
  </div>
</template>
<script>
import { useDisplay } from 'vuetify'
import { useEventStore } from '../stores/event'

export default {
  props: {
    events: {
      type: Array,
      required: false,
      default: () => []
    },
    phases: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  setup() {
    let eventStore = useEventStore()

    let { smAndUp } = useDisplay()
    return { smAndUp, eventStore }
  },
  data() {
    return {
      showFilters: false,
      states: [
        { label: 'Not Started', color: 'blue', value: 1 },
        { label: 'Called', color: 'warning', value: 6 },
        { label: 'Started', color: 'green', value: 2 },
        { label: 'Complete', color: 'grey', value: 3 }
      ]
    }
  },
  computed: {
    phaseGroupItems() {
      let items = []
      this.phases?.forEach((phase) => {
        items.push({ divider: true })
        items.push({ header: phase.name })
        phase.phaseGroups.nodes.forEach((phaseGroup) =>
          items.push({
            title: 'Pool ' + phaseGroup.displayIdentifier,
            phase: phase.name,
            value: phaseGroup.id
          })
        )
      })
      return items
    }
  }
}
</script>
<style></style>
