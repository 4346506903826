import { defineStore } from 'pinia'
import phaseApi from '../api/phase'
import { shuffle } from 'lodash'
import { useNotificationStore } from './notification.js'

export const useSeedStore = defineStore('seed', {
  state: () => ({
    seeds: [],
    phaseId: null,
    originalSeeds: [],
    phaseGroups: {},
    phaseGroupSeedsRaw: {},
    waves: {},
    changed: false,
    loading: false,
    selected: []
  }),
  getters: {},
  actions: {
    reset() {
      this.seeds = []
      this.phaseId = null
      this.originalSeeds = []
      this.phaseGroups = {}
      this.phaseGroupSeedsRaw = {}
      this.waves = {}
      this.changed = false
      this.loading = false
      this.selected = []
    },
    async fetchSeeding(phaseId) {
      this.reset()
      this.loading = true
      this.phaseId = phaseId

      let page = 0
      let totalPages = 0

      let phaseGroupsRes = await phaseApi.getPhaseGroups(phaseId)
      if (this.phaseId != phaseId) {
        return
      }
      phaseGroupsRes.data.data.phase.phaseGroups.nodes.forEach((phaseGroup) => {
        if (this.phaseGroups[phaseGroup.wave?.id]?.length) {
          this.phaseGroups[phaseGroup.wave?.id].push(phaseGroup)
        } else {
          this.phaseGroups[phaseGroup.wave?.id] = [phaseGroup]
        }

        //move this to initial tournament fetch
        this.waves[phaseGroup.wave?.id] = phaseGroup.wave?.identifier
      })

      do {
        let phaseSeedsRes = await phaseApi.getPhaseSeeds(phaseId, page + 1)
        if (this.phaseId != phaseId) {
          return
        }
        this.seeds = [...this.seeds, ...phaseSeedsRes.data.data.phase.seeds.nodes]
        this.originalSeeds = [...this.originalSeeds, ...phaseSeedsRes.data.data.phase.seeds.nodes]
        phaseSeedsRes.data.data.phase.seeds.nodes.forEach((seed) => {
          if (this.phaseGroupSeedsRaw[seed.phaseGroup.id] != undefined) {
            this.phaseGroupSeedsRaw[seed.phaseGroup.id].push(seed.seedNum)
          } else {
            this.phaseGroupSeedsRaw[seed.phaseGroup.id] = [seed.seedNum]
          }
        })

        page = phaseSeedsRes.data.data.phase.seeds.pageInfo.page
        totalPages = phaseSeedsRes.data.data.phase.seeds.pageInfo.totalPages
      } while (page < totalPages)

      this.loading = false
    },
    swapSeeds(seedNum1, seedNum2) {
      this.changed = true
      var b = this.seeds[seedNum1 - 1]
      this.seeds[seedNum1 - 1] = this.seeds[seedNum2 - 1]
      this.seeds[seedNum2 - 1] = b
    },
    moveSeedTo(seedNum1, seedNum2) {
      this.changed = true
      const item = this.seeds.splice(seedNum1 - 1, 1)[0]
      this.seeds.splice(seedNum2 - 1, 0, item)
    },
    moveSeedsTo(seedList) {
      this.changed = true
      let tmpSeeds = []
      seedList.forEach((seed, index) => {
        tmpSeeds.push(this.seeds.splice(seed.oldIndex - index, 1)[0])
      })

      tmpSeeds.forEach((seed, index) => {
        this.seeds.splice(seedList[index].newIndex, 0, seed)
      })
    },
    moveSelectionToTop() {
      this.changed = true
      let tmpSeeds = []
      let sortedSelection = this.selected.slice().sort((a, b) => a - b)
      sortedSelection.forEach((seed, index) => {
        tmpSeeds.push(this.seeds.splice(seed - index - 1, 1)[0])
      })

      this.seeds.splice(0, 0, ...tmpSeeds)
    },
    shuffleAll() {
      this.changed = true
      this.seeds = shuffle(this.seeds)
    },
    shuffleSelection() {
      this.changed = true
      let sortedSelection = this.selected.slice().sort((a, b) => a - b)
      let selectedSeeds = this.seeds.slice(
        sortedSelection[0] - 1,
        sortedSelection[sortedSelection.length - 1]
      )
      let shuffledSeeds = shuffle(selectedSeeds)

      this.seeds.splice(sortedSelection[0] - 1, sortedSelection.length, ...shuffledSeeds)
    },
    resetSeeds() {
      this.changed = false
      this.seeds = [...this.originalSeeds]
    },
    saveSeeds() {
      let notification = useNotificationStore()
      let seedMapping = []
      this.seeds.forEach((seed, index) => {
        if (seed == undefined) {
          return
        }
        seedMapping.push({
          seedId: seed.id,
          seedNum: index + 1
        })
      })
      return phaseApi
        .updatePhaseSeeding(this.phaseId, seedMapping)
        .then((res) => {
          if (res.data.errors) {
            notification.showNotification('Failed to save seeding D:, try again?', 'error')
            return
          }
          notification.showNotification('Successfully saved seeding', 'success')
          this.originalSeeds = [...this.seeds]
          this.changed = false
          localStorage.clear(`phaseSeeds-${this.phaseId}`)
        })
        .catch(() => {
          notification.showNotification('Failed to save seeding D:, try again?', 'error')
        })
    }
  }
})
