<template>
  <div class="text-h2 d-flex flex-column align-center h-screen">
    We're logging you in...
    <div class="d-flex flex-column align-center w-100">
      <v-progress-circular
        indeterminate
        color="accent"
        :width="10"
        :size="125"
      ></v-progress-circular>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { usePlausible } from '../composables/plausible.js'

export default {
  setup() {
    const { trackEvent } = usePlausible()

    return { trackEvent }
  },
  mounted() {
    if (this.$route.query.code) {
      axios
        .get(`/oauth_redirect?code=${this.$route.query.code}`)
        .then((res) => {
          this.$cookies.set('access_token', res.data.access_token, res.data.max_age)
          this.trackEvent('login')
          window.open('/', '_self')
        })
        .catch((err) => {
          console.log(err)
          this.trackEvent('login_err')
          this.$cookies.remove('access_token')
          this.$router.push('/about')
        })
    } else if (this.$route.query.token) {
      axios
        .get(`/oauth_refresh?token=${this.$route.query.token}`)
        .then((res) => {
          this.$cookies.set('access_token', res.data.access_token, res.data.max_age)
          this.trackEvent('refresh')
          window.open('/', '_self')
        })
        .catch((err) => {
          console.log(err)
          this.trackEvent('refresh_err')
          this.$cookies.remove('access_token')
          this.$router.push('/about')
        })
    } else {
      this.$router.push('/about')
      return
    }
  }
}
</script>
<style></style>
