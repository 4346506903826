<template>
  <div>
    <v-img
      :src="banner"
      class="rounded-te-xl"
      min-height="75px"
      max-height="75px"
      cover
      style="filter: brightness(20%)"
    >
    </v-img>
    <div style="min-height: 75px; max-height: 75px; margin-top: -75px" class="d-flex align-center">
      <v-avatar rounded="lg" class="mx-4"><v-img :src="avatar"></v-img></v-avatar>
      <div class="mr-2" style="z-index: 10">{{ tournamentStore.tournament?.name }}</div>
    </div>
    <div class="mx-2">
      <v-list v-model="selected" v-model:opened="open" mandatory open-strategy="single">
        <template v-for="event in tournamentStore.tournament?.events" :key="event.id">
          <v-list-item
            :title="event.name"
            :value="event.id"
            :append-icon="selected[0] == event.id ? 'mdi-chevron-down' : null"
            :to="generateRoute(event)"
            color="accent"
            rounded="lg"
          >
            <template #prepend>
              <v-avatar :image="event.videogame.images[0].url" rounded="lg"> </v-avatar>
            </template>

            <template v-if="event.state == 'ACTIVE'" #subtitle>
              <div class="text-success">• In Progress</div>
            </template>
          </v-list-item>
          <div v-if="selected[0] == event.id" class="py-1">
            <v-list-item
              density="compact"
              rounded="lg"
              :active="$route.name == 'reporting'"
              title="Reporting"
              prepend-icon="mdi-pencil"
              color="accent"
              :to="{
                name: 'reporting',
                params: { tournamentId: tournamentStore.tournament.id, eventId: event.id }
              }"
            ></v-list-item>
            <v-list-group value="seeding">
              <template #activator="{ props }">
                <v-list-item
                  v-bind="props"
                  density="compact"
                  rounded="lg"
                  :active="$route.name == 'seeding'"
                  title="Seeding"
                  prepend-icon="mdi-format-list-numbered"
                  color="accent"
                >
                </v-list-item>
              </template>
              <v-list-item
                v-for="phase in event.phases"
                :key="phase.id"
                density="compact"
                rounded="lg"
                :title="phase.name"
                :to="{
                  name: 'seeding',
                  params: {
                    tournamentId: tournamentStore.tournament.id,
                    eventId: event.id,
                    phaseId: phase.id
                  }
                }"
              ></v-list-item>
            </v-list-group>
          </div>
        </template>
      </v-list>
    </div>
  </div>
</template>
<script>
import { useEventStore } from '../stores/event'
import { useTournamentStore } from '../stores/tournament'
export default {
  setup() {
    let tournamentStore = useTournamentStore()
    let eventStore = useEventStore()

    return { tournamentStore, eventStore }
  },
  data: () => {
    return {
      open: [],
      selected: [],
      fileFormats: ['.jpg', '.jpeg', '.png']
    }
  },
  computed: {
    avatar() {
      let src = this.tournamentStore.tournament?.images?.filter(
        (image) => image.type === 'profile'
      )[0]?.url
      if (src) {
        let imageIndex
        let imageFormat
        this.fileFormats.forEach((format) => {
          if (imageFormat) {
            return
          }
          imageIndex = src.indexOf(format)
          if (imageIndex > -1) {
            imageFormat = format
          }
        })
        let imageAddress = src.slice(0, imageIndex) + '-optimized' + imageFormat
        let query = 'ehk' + src.slice(src.indexOf('&ehkOptimized=') + 13)
        return `https://www.bing.com/th?pid=Sgg&qlt=100&u=${encodeURIComponent(
          imageAddress
        )}&${query}&w=64&r=0`
      }
      return null
    },
    banner() {
      // let src = this.tournament.images?.filter((image) => image.type === 'banner')[0]?.url
      // if (src && this.useDetectedColor) {
      //   return '/cors?url=' + encodeURIComponent(src)
      // }
      // return src
      let src = this.tournamentStore.tournament?.images?.filter(
        (image) => image.type === 'banner'
      )[0]?.url
      if (src) {
        let imageIndex
        let imageFormat
        this.fileFormats.forEach((format) => {
          if (imageFormat) {
            return
          }
          imageIndex = src.indexOf(format)
          if (imageIndex > -1) {
            imageFormat = format
          }
        })
        let imageAddress = src.slice(0, imageIndex) + '-optimized' + imageFormat
        let query = 'ehk' + src.slice(src.indexOf('&ehkOptimized=') + 13)
        return `https://www.bing.com/th?pid=Sgg&qlt=100&u=${encodeURIComponent(
          imageAddress
        )}&${query}&w=920&r=0`
      }
      return null
    }
  },
  watch: {
    '$route.params.eventId'() {
      this.selected = [parseInt(this.$route.params.eventId)]
    },
    '$route.name'() {
      if (this.$route.name) {
        this.open = [this.$route.name]
      }
    }
  },
  mounted() {
    this.selected = [parseInt(this.$route.params.eventId)]
  },
  methods: {
    generateRoute(event) {
      let route = {
        params: {
          tournamentId: this.tournamentStore.tournament.id,
          eventId: event.id
        }
      }

      if (this.$route.name == 'seeding' && event.phases.length) {
        route.name = 'seeding'
        route.params.phaseId = event.phases[0].id
      } else {
        route.name = 'reporting'
      }

      return route
    }
  }
}
</script>
<style></style>
