<template lang="">
  <TournamentCardInfiniteScroll />
</template>
<script>
import TournamentCardInfiniteScroll from '../components/TournamentCardInfiniteScroll.vue'
import { useCurrUserStore } from '../stores/currentUser'
import { useSearchStore } from '../stores/search'
import { debounce } from 'lodash'

export default {
  components: { TournamentCardInfiniteScroll },
  setup() {
    let currUserStore = useCurrUserStore()
    let searchStore = useSearchStore()

    return { currUserStore, searchStore }
  },
  data() {
    return {
      search: debounce(this.searchTournaments, 500)
    }
  },
  watch: {
    'searchStore.value'() {
      this.search()
    }
  },
  mounted() {
    this.currUserStore.init({}, 12, null)
    this.currUserStore.fetchTournaments()
  },
  methods: {
    searchTournaments() {
      if (!this.searchStore.value) {
        this.currUserStore.init({}, 12, null)
      } else {
        this.currUserStore.init(
          { search: { searchString: this.searchStore.value, fieldsToSearch: ['tournament.name'] } },
          12,
          null
        )
      }

      this.currUserStore.fetchTournaments()
    }
  }
}
</script>
<style lang=""></style>
