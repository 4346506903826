<template>
  <div :class="`player player1 d-flex justify-space-between text-truncate ${colors[0]}`">
    <span>{{ entrantText(0) }}</span>
    <div class="score">
      <p :style="{ color: scoreColor }">{{ scores[0] }}</p>
    </div>
  </div>
  <div :class="`player player2 d-flex justify-space-between text-truncate ${colors[1]}`">
    <span>{{ entrantText(1) }}</span>
    <div class="score">
      <p :style="{ color: scoreColor }">{{ scores[1] }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    set: {
      type: Object,
      required: true
    }
  },
  computed: {
    colors() {
      if (this.set.state !== 3) {
        return ['bg-white', 'bg-white']
      }
      let entrant = this.set.slots[0].entrant.id
      return entrant == this.set.winnerId ? ['bg-green', 'bg-red'] : ['bg-red', 'bg-green']
    },
    scores() {
      let out = [
        this.set.slots[0].standing?.stats.score.value,
        this.set.slots[1].standing?.stats.score.value
      ]
      if (out[0] === -1) return ['DQ', 'W']
      if (out[1] === -1) return ['W', 'DQ']
      return out
    },
    scoreColor() {
      return this.set.state == 3 ? 'white' : 'black'
    }
  },
  methods: {
    entrantText(slot) {
      if (this.set.slots[slot].prereqType == 'bye') {
        return 'bye'
      }
      return this.set.slots[slot].entrant ? this.set.slots[slot].entrant.name : ''
    }
  }
}
</script>

<style>
.player {
  height: 24px;
  border: 1px lightgrey solid;
  padding-left: 4px;
  opacity: 60%;
}
.player1 {
  border-radius: 4px 4px 0px 0px;
}
.player2 {
  border-top: 0px;
  border-radius: 0px 0px 4px 4px;
}
.set {
  margin-top: 10px;
}
.score {
  align-self: end;
  padding: 0px 4px;
  border: 0px solid;
}
</style>
