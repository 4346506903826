import axios from './axios.js'

let setQuery = `
    id
    state
    event {
        id
    }
    games {
        id
        orderNum
        selections {
            id
            character {
            id
            }
            entrant {
            id
            }
        }
        stage {
            id
            name
        }
        winnerId
    }
    startedAt
    fullRoundText
    identifier
    winnerId
    resetAffectedData {
      affectedSetCount
    }
    phaseGroup {
        id
        displayIdentifier
        phase {
            id
            name
        }
    }
    slots {
        id
        standing {
            placement
            stats {
                score {
                    value
                }
            }
        }
        prereqPlacement
        prereqType
        entrant {
            id
            name
        }
    }
`

export default {
  startSet(setId) {
    let query = `
            mutation startSet($setId: ID!) {
                markSetInProgress(setId: $setId) {
                    ${setQuery}
                }
            }`
    let variables = { setId }

    return axios.post('', { query, variables })
  },
  callSet(setId) {
    let query = `
            mutation callSet($setId: ID!) {
                markSetCalled(setId: $setId) {
                    ${setQuery}
                }
            }`
    let variables = { setId }

    return axios.post('', { query, variables })
  },
  resetSet(setId, resetDependentSets) {
    let query = `
            mutation resetSet($setId: ID!, $resetDependentSets: Boolean) {
                resetSet(setId: $setId, resetDependentSets: $resetDependentSets) {
                    ${setQuery}
                }
            }`
    let variables = { setId, resetDependentSets }

    return axios.post('', { query, variables })
  },
  detailedReport(setId, winnerId, gameData, isDq) {
    let query = `
            mutation reportDetailedSet($setId: ID!, $winnerId: ID!, $gameData: [BracketSetGameDataInput], $isDq: Boolean) {
                reportBracketSet(setId: $setId, winnerId: $winnerId, gameData: $gameData, isDQ: $isDq) {
                    ${setQuery}
                }
            }`

    let variables = { setId, winnerId, gameData, isDq }

    return axios.post('', { query, variables })
  },
  detailedUpdate(setId, winnerId, gameData, isDq) {
    let query = `
            mutation updateDetailedSet($setId: ID!, $winnerId: ID!, $gameData: [BracketSetGameDataInput], $isDq: Boolean) {
                updateBracketSet(setId: $setId, winnerId: $winnerId, gameData: $gameData, isDQ: $isDq) {
                    ${setQuery}
                }
            }`

    let variables = { setId, winnerId, gameData, isDq }

    return axios.post('', { query, variables })
  },
  detailedSave(setId, gameData) {
    let query = `
            mutation saveDetailedSet($setId: ID!, $gameData: [BracketSetGameDataInput]) {
                reportBracketSet(setId: $setId, winnerId: null, gameData: $gameData) {
                    ${setQuery}
                }
            }`

    let variables = { setId, gameData }

    return axios.post('', { query, variables })
  },
  upgradePreview(setId) {
    let query = `
            mutation upgradePreview($setId: ID!) {
                reportBracketSet(setId: $setId) {
                    ${setQuery}
                }
            }`

    let variables = { setId }

    return axios.post('', { query, variables })
  },
  getResetAffectedData(setId) {
    let query = `
            query getResetAffectedData($setId: ID!) {
                set(id: $setId) {
                    resetAffectedData {
                        affectedSets {
                            id
                            round
                            winnerId
                            slots {
                                id
                                standing {
                                    stats {
                                        score {
                                            value
                                        }
                                    }
                                }
                                entrant {
                                    id
                                }
                            }
                            games {
                                id
                                orderNum
                                selections {
                                    id
                                    character {
                                        id
                                    }
                                    entrant {
                                        id
                                    }
                                }
                                stage {
                                    id
                                }
                                winnerId
                            }
                        }
                    }
                }
            }`
    let variables = { setId }

    return axios.post('', { query, variables })
  },
  reReportMultiple(setId, reportData) {
    let reportString = ``
    let variableString = ``
    let variables = { setId, resetDependentSets: true }
    reportData.forEach((set) => {
      reportString += `
            set_${set.setId}: reportBracketSet(setId: ${set.setId}, winnerId: ${set.winnerId}, gameData: $gameData_${set.setId}, isDQ: ${set.isDq}) {
                ...setData
            }
        `

      variableString += `$gameData_${set.setId}: [BracketSetGameDataInput], `
      variables[`gameData_${set.setId}`] = set.gameData
    })

    let query = `
        mutation reReportMultiple($setId: ID!, $resetDependentSets: Boolean, ${variableString}) {
            resetSet(setId: $setId, resetDependentSets: $resetDependentSets) {
                ...setData
            }

            ${reportString}
        }

        fragment setData on Set {
            ${setQuery}
        }
    `

    return axios.post('', { query, variables }, { timeout: 0 })
  }
}
