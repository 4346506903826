import { defineStore } from 'pinia'

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    color: 'success',
    visible: false,
    text: ''
  }),
  getters: {},
  actions: {
    showNotification(text, color) {
      this.text = text
      this.color = color
      this.visible = true
    }
  }
})
