import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'

/*

// const lightTheme = {
//   dark: false,
//   colors: {
//     background: '${bgL.hex}',
//     surface: '${bgL.hex.90}',
//     primary: '${primaryL.hex}',
//     secondary: '${secondaryL.hex}',
//     accent: '${accentL.hex}',
//   },
// }

// const darkTheme = {
//   dark: true,
//   colors: {
//     background: '${bgD.hex}',
//     surface: '${bgD.hex.10}',
//     primary: '${primaryD.hex}',
//     secondary: '${secondaryD.hex}',
//     accent: '${accentD.hex}',
//   },
// }

*/

// https://www.realtimecolors.com/?colors=d5e2fb-01060e-8cb0f3-05132e-0044c2

const startGGBlueLight = {
  name: 'Start.GG Blue',
  dark: false,
  colors: {
    background: '#f1f6fe',
    surface: '#cfe0fc',
    primary: '#0c3073',
    secondary: '#d1dffa',
    accent: '#3d81ff'
  }
}

const startGGBlueDark = {
  name: 'Start.GG Blue',
  dark: true,
  colors: {
    background: '#01060e',
    surface: '#031430',
    primary: '#8cb0f3',
    secondary: '#05132e',
    accent: '#0044c2'
  }
}

const boringBlack = {
  name: 'Boring Black',
  dark: true,
  colors: {
    background: '#121212',
    surface: '#212121',
    primary: '#212121',
    secondary: '#303030',
    accent: '#e0245d'
  }
}

// https://www.realtimecolors.com/?colors=fffafc-090103-f2638e-420518-db1f57

const startGGRedLight = {
  name: 'Start.GG Red',
  dark: false,
  colors: {
    background: '#fef6f8',
    surface: '#fad1db',
    primary: '#9c0d38',
    secondary: '#fabdd0',
    accent: '#e0245d'
  }
}

const startGGRedDark = {
  name: 'Start.GG Red',
  dark: true,
  colors: {
    background: '#090103',
    surface: '#2e050f',
    primary: '#f2638e',
    secondary: '#420518',
    accent: '#db1f57'
  }
}

export default createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi
    }
  },
  theme: {
    defaultTheme: localStorage.getItem('theme') ?? 'startGGRedDark',
    themes: {
      startGGBlueLight,
      startGGBlueDark,
      startGGRedLight,
      startGGRedDark,
      boringBlack
    }
  }
})
