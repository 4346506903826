<template lang="">
  <div>
    <v-card :color="color" :to="{ name: 'event', params: { tournamentId: tournament.id } }">
      <v-img :src="banner" height="200px" cover @load="loadPalette"></v-img>
      <div class="d-flex">
        <div class="d-flex flex-column w-100">
          <v-card-title>
            {{ tournament.name }}
          </v-card-title>
          <v-card-text class="w-100 d-flex justify-space-between">
            <div class="d-flex flex-column">
              <div>
                <v-icon>mdi-calendar</v-icon>
                {{ startDate }} {{ startDate === endDate ? '' : `- ${endDate}` }}
              </div>
              <div v-if="tournament.isOnline">
                <v-icon>mdi-wifi</v-icon>
                Online
              </div>
              <div v-else>
                <v-icon>mdi-map-marker</v-icon>
                {{ tournament.city }}, {{ tournament.countryCode }}
              </div>
            </div>
            <div class="d-flex flex-column">
              <div>
                <v-icon>mdi-account</v-icon>
                {{ tournament.numAttendees }} Attendees
              </div>
              <div>
                <v-icon>mdi-card-multiple</v-icon>
                {{ tournament.events.length }} Events
              </div>
            </div>
          </v-card-text>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { format } from 'date-fns'
import * as Vibrant from 'node-vibrant'
import { useTheme } from 'vuetify'

export default {
  props: {
    tournament: {
      type: Object,
      required: true
    },
    useDetectedColor: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    let { current } = useTheme()

    return { current }
  },
  data: function () {
    return {
      palette: null,
      fileFormats: ['.jpg', '.jpeg', '.png']
    }
  },
  computed: {
    banner() {
      // let src = this.tournament.images?.filter((image) => image.type === 'banner')[0]?.url
      // if (src && this.useDetectedColor) {
      //   return '/cors?url=' + encodeURIComponent(src)
      // }
      // return src
      let src = this.tournament.images?.filter((image) => image.type === 'banner')[0]?.url
      if (src) {
        let imageIndex
        let imageFormat
        this.fileFormats.forEach((format) => {
          if (imageFormat) {
            return
          }
          imageIndex = src.indexOf(format)
          if (imageIndex > -1) {
            imageFormat = format
          }
        })
        let imageAddress = src.slice(0, imageIndex) + '-optimized' + imageFormat
        let query = 'ehk' + src.slice(src.indexOf('&ehkOptimized=') + 13)
        return `https://www.bing.com/th?pid=Sgg&qlt=100&u=${encodeURIComponent(
          imageAddress
        )}&${query}&w=920&r=0`
      }
      return null
    },
    startDate() {
      return format(new Date(this.tournament.startAt * 1000), 'MMM d, yyyy')
    },
    endDate() {
      return format(new Date(this.tournament.endAt * 1000), 'MMM d, yyyy')
    },
    color() {
      return this.current.dark ? this.palette?.DarkVibrant.hex : this.palette?.LightVibrant.hex
    }
  },
  methods: {
    loadPalette() {
      Vibrant.from(this.banner)
        .getPalette()
        .then((palette) => (this.palette = palette))
    }
  }
}
</script>
<style lang=""></style>
