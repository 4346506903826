<template>
  <div class="d-flex flex-column overflow-hidden">
    <v-text-field
      v-model="search"
      variant="underlined"
      prepend-inner-icon="mdi-magnify"
      hide-details
      label="Search"
      class="ma-2"
    ></v-text-field>
    <div class="overflow-y-auto">
      <div v-if="recommendedStages.length && !search">
        <div class="text-overline">Recommended Stages</div>
        <button
          v-for="stage in recommendedStages"
          :key="stage.id"
          class="stageButton"
          @click="$emit('select', stage)"
        >
          {{ stage.name }}
        </button>
        <v-divider></v-divider>
      </div>
      <button
        v-for="stage in filtered"
        :key="stage.id"
        class="stageButton"
        @click="$emit('select', stage)"
      >
        {{ stage.name }}
      </button>
    </div>
  </div>
</template>
<script>
import { useEventStore } from '../stores/event'

export default {
  emits: ['select'],
  setup() {
    let eventStore = useEventStore()

    return { eventStore }
  },
  data() {
    return {
      search: '',
      stages: [],
      recommended: {
        1: [19, 11, 25, 20, 22, 15], //Smash Melee
        1386: [311, 484, 378, 397, 387, 328, 348, 513, 407, 353] //Smash Ultimate
      }
    }
  },
  computed: {
    filtered() {
      return this.stages.filter((stage) =>
        stage.name
          .normalize('NFD')
          .replace(/[^a-z]/gi, '')
          .toLowerCase()
          .includes(this.search.toLowerCase())
      )
    },
    recommendedStages() {
      let result = []
      if (this.recommended[this.eventStore.game.id]) {
        this.stages.forEach((stage) => {
          if (this.recommended[this.eventStore.game.id].includes(stage.id)) {
            result.push(stage)
          }
        })
      }

      return result
    }
  },
  mounted() {
    this.stages = this.eventStore.game?.stages
  }
}
</script>
<style>
.stageButton {
  width: 100%;
  padding: 2px;
  border-radius: 4px;
}

.stageButton:hover {
  background-color: rgba(211, 211, 211, 0.2);
}
</style>
