<template>
  <div>
    <v-card>
      <div class="d-flex flex-no-wrap">
        <div style="width: 93px; height: 125px" class="ma-2">
          <v-img :src="gameImage" width="93" height="125" class="rounded-lg"></v-img>
        </div>
        <div>
          <v-card-title class="text-h5"> {{ event.name }} </v-card-title>

          <v-card-subtitle>{{ startDate }}</v-card-subtitle>
          <v-card-subtitle
            >{{ eventTypeMap[event.type] }} • {{ event.videogame.name }}</v-card-subtitle
          >

          <v-card-actions>
            <v-btn
              class="ms-2"
              variant="outlined"
              append-icon="mdi-pencil"
              :to="{
                name: 'reporting',
                params: { tournamentId: tournamentId, eventId: event.id }
              }"
            >
              Report
            </v-btn>

            <v-btn
              v-if="event.phases.length"
              class="ms-2"
              variant="outlined"
              append-icon="mdi-format-list-numbered"
              :to="{
                name: 'seeding',
                params: {
                  tournamentId: tournamentId,
                  eventId: event.id,
                  phaseId: event.phases[0].id
                }
              }"
            >
              Seed
            </v-btn>
          </v-card-actions>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import { format } from 'date-fns'

export default {
  props: {
    event: {
      type: Object,
      required: true
    },
    tournamentId: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      eventTypeMap: {
        1: 'Singles',
        5: 'Teams'
      }
    }
  },
  computed: {
    startDate() {
      return format(new Date(this.event.startAt * 1000), 'MMM d, yyyy')
    },
    gameImage() {
      return this.event.videogame.images.filter((image) => image.type == 'primary')[0]?.url
    }
  }
}
</script>
<style></style>
