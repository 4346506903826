<template>
  <div class="mb-6 title-wrapper">
    <div
      :class="{ title: true, 'd-flex': true, 'text-center': true, 'text-h1': !xs, 'text-h2': xs }"
    >
      <div style="color: #3d81ff">Start</div>
      <div style="color: #e0245d">Kit</div>
      <div>.gg</div>
    </div>
    <div v-if="current.dark" class="title-bg"></div>
  </div>
</template>
<script>
import { useDisplay, useTheme } from 'vuetify/lib/framework.mjs'

export default {
  setup() {
    let { xs } = useDisplay()
    let { current } = useTheme()

    return { xs, current }
  }
}
</script>
<style scoped>
@font-face {
  font-family: Audiowide;
  src: url(/fonts/Audiowide-Regular.ttf);
}
.title {
  font-family: Audiowide !important;
  z-index: 1;
  position: relative;
}
.title-wrapper {
  position: relative;
  margin-top: 84px;
}
.title-bg {
  filter: blur(48px);
  background: linear-gradient(90deg, rgba(61, 129, 255, 0.5) 50%, rgba(224, 36, 93, 0.5) 50%);
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
