import './assets/main.css'
import '@mdi/font/css/materialdesignicons.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import vuetify from './vuetify'

import App from './App.vue'
import router from './router'
import VueCookies from 'vue-cookies'
import { createPlausible } from 'v-plausible/vue'
import * as Sentry from '@sentry/vue'

const app = createApp(App)

app.use(createPinia())
app.use(router)

app.use(VueCookies)

let redirectBlacklist = ['about', 'oauth', 'privacy']

router.beforeEach((to) => {
  if (
    !app.$cookies.get('access_token') &&
    !redirectBlacklist.includes(to.name) &&
    !import.meta.env.DEV
  ) {
    router.push('/about')
  }
})

app.use(vuetify)

let optInStatus =
  localStorage.getItem('optIn') === null ? true : localStorage.getItem('optIn') === 'true'

const plausible = createPlausible({
  init: {
    domain: 'startkit.gg',
    trackLocalhost: false
  },
  settings: {
    enableAutoOutboundTracking: optInStatus,
    enableAutoPageviews: optInStatus
  }
})

Sentry.init({
  app,
  dsn: 'https://4c9c91b65e2c7f950cd000564151562c@o4507051444338688.ingest.us.sentry.io/4507052158681088',
  autoSessionTracking: optInStatus,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/startkit\.gg\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

let user = [...Array(8)].map(() => Math.floor(Math.random() * 16).toString(16)).join('')

Sentry.setUser({ id: user })
console.log('Current Session: ' + user)

app.use(plausible)

app.mount('#app')
