<template lang="">
  <div>
    <v-alert
      v-if="showRestoreAlert"
      icon="mdi-restore-alert"
      title="Restore Data"
      color="info"
      variant="tonal"
    >
      There looks like there's some unsaved data here, would you like to restore it?
      <br />
      <v-btn variant="text" color="accent" @click="restoreData">Restore</v-btn>
      <v-btn variant="text" @click="showRestoreAlert = false">Dismiss</v-btn>
    </v-alert>
    <div class="container">
      <div v-if="smAndDown" class="d-flex justify-end">
        <SeedActions :show-phase-actions="tab == 'phase'" @deselect="deselectAll"></SeedActions>
      </div>
      <div class="d-flex align-center justify-space-between">
        <v-tabs v-if="Object.keys(seedStore.phaseGroupSeedsRaw).length > 1" v-model="tab">
          <v-tab value="pools">Pools</v-tab>
          <v-tab value="phase">Phase</v-tab>
        </v-tabs>
        <v-spacer v-else></v-spacer>
        <div v-if="!smAndDown">
          <SeedActions :show-phase-actions="tab == 'phase'" @deselect="deselectAll"></SeedActions>
        </div>
      </div>
      <div class="overflow-auto h-100">
        <v-window v-model="tab" :touch="false">
          <v-window-item value="pools">
            <PoolsCollection></PoolsCollection>
          </v-window-item>
          <v-window-item
            value="phase"
            style="height: 50%"
            :transition="Object.keys(seedStore.phaseGroupSeedsRaw).length == 1 ? false : null"
          >
            <SortableList
              :key="`phase-${update}`"
              v-model:selected="seedStore.selected"
              :list="seedStore.seeds"
              multi-drag
              vertical-wrap
              @update-seed="onUpdate"
            ></SortableList>
          </v-window-item>
        </v-window>
      </div>
    </div>
  </div>
</template>
<script>
import { useDisplay } from 'vuetify/lib/framework.mjs'
import PoolsCollection from '../components/PoolsCollection.vue'
import SortableList from '../components/SortableList.vue'
import SeedActions from '../components/SeedActions.vue'
import { useSeedStore } from '../stores/seed'
import { useTournamentStore } from '../stores/tournament'

export default {
  components: { PoolsCollection, SortableList, SeedActions },
  setup() {
    let { smAndDown } = useDisplay()
    let seedStore = useSeedStore()
    let tournamentStore = useTournamentStore()
    return { smAndDown, seedStore, tournamentStore }
  },
  data() {
    return {
      tab: 'pools',
      update: 0,
      showRestoreAlert: false
    }
  },
  watch: {
    async '$route.params.phaseId'() {
      this.tab = 'pools'
      if (!this.$route.params.phaseId) {
        return
      }
      await this.seedStore.fetchSeeding(this.$route.params.phaseId)
      if (Object.keys(this.seedStore.phaseGroupSeedsRaw).length == 1) {
        this.tab = 'phase'
      }
    },
    'seedStore.seeds': {
      deep: true,
      handler() {
        if (this.seedStore.changed) {
          localStorage.setItem(
            `phaseSeeds-${this.seedStore.phaseId}`,
            JSON.stringify(this.seedStore.seeds)
          )
        }
      }
    }
  },
  async beforeMount() {
    let tournamentId = this.$route.params.tournamentId
    this.tournamentStore.fetchTournamentDetails(tournamentId)
    await this.seedStore.fetchSeeding(this.$route.params.phaseId)
    if (Object.keys(this.seedStore.phaseGroupSeedsRaw).length == 1) {
      this.tab = 'phase'
    }

    if (localStorage.getItem(`phaseSeeds-${this.seedStore.phaseId}`)) {
      this.showRestoreAlert = true
    }
  },
  methods: {
    onUpdate({ newIndicies, oldIndex, newIndex }) {
      if (newIndicies.length) {
        this.seedStore.moveSeedsTo(newIndicies)
      } else {
        this.seedStore.moveSeedTo(oldIndex + 1, newIndex + 1)
      }
    },
    restoreData() {
      this.showRestoreAlert = false
      this.seedStore.changed = 'true'
      this.seedStore.seeds = [
        ...JSON.parse(localStorage.getItem(`phaseSeeds-${this.seedStore.phaseId}`))
      ]
    },
    deselectAll() {
      this.seedStore.selected = []
    }
  }
}
</script>
<style scoped>
.container {
  height: calc(100dvh - 96px);
  overflow: hidden;
}
</style>
