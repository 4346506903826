<template lang="">
  <div>
    <v-list density="compact" bg-color="transparent" class="pa-0">
      <Sortable
        :id="phaseId"
        v-scroll.self="scroll"
        :list="list"
        item-key="id"
        :options="options"
        class="overflow-y-hidden sortable"
        :class="{ 'vertical-wrap': verticalWrap }"
        :style="{
          height: verticalWrap ? listHeight : null,
          'max-width': verticalWrap ? listWidth : null
        }"
        @end="onEnd"
        @start="startMove()"
      >
        <template #item="{ element, index }">
          <div
            :id="multiDrag ? `seed-${seedList ? seedList[index] : index + 1}` : null"
            class="sortable-item"
            :style="{ width: verticalWrap ? '220px' : null }"
            :class="{
              'mr-4': verticalWrap
            }"
          >
            <v-list-item class="cursor-pointer py-0">
              <template #default>
                <div class="d-flex h-100">
                  <div class="seed-container">{{ index + 1 }}</div>
                  <div class="text-truncate">
                    {{ element.placeholderName ?? element.entrant.name }}
                  </div>
                  <v-spacer></v-spacer>
                  <div v-if="seedList">{{ seedList[index] }}</div>
                </div>
              </template>
            </v-list-item>
          </div>
        </template>
      </Sortable>
    </v-list>
  </div>
</template>
<script>
import { Sortable as SortableJs, MultiDrag } from 'sortablejs'
import Swap from '../sortable/CustomSwap'
SortableJs.mount(new Swap())
SortableJs.mount(new MultiDrag())
import { Sortable } from 'sortablejs-vue3'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import { useVibrate } from '@vueuse/core'

export default {
  components: { Sortable },
  props: {
    list: {
      type: Array,
      required: true
    },
    phaseId: {
      type: Number,
      required: true
    },
    swap: {
      type: Boolean,
      default: false,
      required: false
    },
    verticalWrap: {
      type: Boolean,
      default: false,
      required: false
    },
    seedList: {
      type: Array,
      required: false,
      default: null
    },
    multiDrag: {
      type: Boolean,
      default: false,
      required: false
    },
    selected: {
      type: Array,
      required: false,
      default: () => []
    } //Not actually a 2 way bind because sortable select function is buggy - might make my own in the future
  },
  emits: ['updateSeed', 'update:selected'],
  setup() {
    let { height, platform } = useDisplay()
    let { vibrate, isSupported } = useVibrate({ pattern: [15] })

    return { height, platform, vibrate, isSupported }
  },
  data() {
    return {
      drag: false,
      losers: null,
      internalSelected: [],
      scrollTimer: null,
      scrolling: false
    }
  },
  computed: {
    options() {
      let options = {
        group: 'seeding',
        delay: 250,
        delayOnTouchOnly: true,
        animation: 0,
        ghostClass: 'ghost',
        scroll: true,
        forceFallback: true,
        bubbleScroll: true,
        swap: true,
        swapClass: 'swapping',
        dragClass: 'dragging',
        multiDrag: this.multiDrag && !this.scrolling,
        avoidImplicitDeselect: true,
        selectedClass: 'selected',
        onSelect: this.onSelect,
        onDeselect: this.onDeselect
      }

      if (!this.platform.touch) {
        options.multiDragKey = 'CTRL'
      }

      return options
    },
    listHeight() {
      return Math.floor((this.height - 200) / 40) * 40 + 'px'
    },
    listWidth() {
      return Math.ceil(this.list.length / Math.floor((this.height - 200) / 40)) * 236 + 'px'
    }
  },
  watch: {
    selected: {
      deep: true,
      handler(newVal, oldVal) {
        oldVal.forEach((val) => {
          if (newVal.indexOf(val) == -1) {
            SortableJs.utils.deselect(document.getElementById('seed-' + val))
          }
        })

        this.$nextTick(() => {
          newVal.forEach((val) => {
            SortableJs.utils.select(document.getElementById('seed-' + val))
          })
        })
        this.internalSelected = newVal
      }
    }
  },
  methods: {
    startMove() {
      if (this.isSupported) {
        this.vibrate()
      }
    },
    scroll() {
      this.scrolling = true
      if (this.scrollTimer !== null) {
        clearTimeout(this.scrollTimer)
      }
      this.scrollTimer = setTimeout(() => {
        this.scrolling = false
      }, 100)
    },
    onEnd({ to, oldIndex, newIndex, newIndicies }) {
      let newSeeds = []
      if (newIndicies.length) {
        this.internalSelected = []
        newIndicies.forEach((index) => {
          this.internalSelected.push(index.index + 1)
          newSeeds.push({
            oldIndex: parseInt(index.multiDragElement.id.split('-')[1]) - 1,
            newIndex: index.index
          })
        })
        this.$emit('update:selected', this.internalSelected)
      }
      this.$emit('updateSeed', {
        newIndicies: newSeeds,
        fromPhaseGroup: this.phaseId,
        toPhaseGroup: to.id,
        oldIndex,
        newIndex
      })
    },
    resolveLoserPlacement() {
      this.list.forEach((seed) => {
        if (seed.progressionSource.originPlacement > this.losers) {
          this.losers = seed.progressionSource.originPlacement
        }
      })
    },
    onSelect(evt) {
      this.internalSelected.push(parseInt(evt.item.id.split('-')[1]))
      this.$emit('update:selected', this.internalSelected)
    },
    onDeselect(evt) {
      let seed = parseInt(evt.item.id.split('-')[1])
      let index = this.internalSelected.indexOf(seed)
      this.internalSelected.splice(index, 1)
      this.$emit('update:selected', this.internalSelected)
    }
  }
}
</script>
<style lang="scss">
.ghost {
  opacity: 0.5;
}

.swapping {
  background-color: rgba(255, 255, 255, 0.5) !important;
  color: black;
}

.sortable .sortable-item:nth-child(odd) {
  background-color: rgb(var(--v-theme-surface));
  color: rgb(var(--v-theme-on-surface));
  max-height: 40px;
}

.sortable .sortable-item:nth-child(even) {
  color: rgb(var(--v-theme-on-secondary));
  background-color: rgb(from rgb(var(--v-theme-surface)) calc(r * 1.5) calc(g * 1.5) calc(b * 1.5));
  max-height: 40px;
}

.vertical-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

.seed-container {
  min-width: 24px;
  margin-right: 8px;
  padding-right: 2px;
}

.selected {
  background-color: rgb(var(--v-theme-accent)) !important;
}
</style>
