import { defineStore } from 'pinia'
import tournamentApi from '../api/tournament'
import currentUser from '../api/currentUser'

export const useTournamentStore = defineStore('tournament', {
  state: () => ({ tournament: null, isAdmin: false }),
  actions: {
    async fetchTournamentDetails(tournamentId) {
      if (this.tournament?.id == tournamentId && this.tournament != null) {
        return
      }
      this.tournament = null
      let res = await tournamentApi.getEventsFromTournament(tournamentId)
      this.tournament = res.data.data.tournament
      this.isAdmin = await currentUser.isCurrentUserAdmin(this.tournament.name, this.tournament.id)
    }
  }
})
