import { defineStore } from 'pinia'
import eventApi from '../api/event.js'
import gameApi from '../api/game.js'
import setApi from '../api/set.js'
import { debounce } from 'lodash'

const ORDER = [4, 0, 5, 2, 6, 1, 3]

export const useEventStore = defineStore('event', {
  state: () => ({
    id: null,
    sets: [],
    setsState: {},
    eventDetails: {},
    pageDetails: { page: 0 },
    game: {},
    setFilters: {
      eventIds: [],
      hideEmpty: true,
      phaseGroupIds: [],
      state: [1, 6, 2, 3],
      entrantIds: []
    },
    props: null,
    refreshSeconds: [30],
    refreshInterval: null,
    refreshing: false,
    refreshCounter: 0,
    refreshPaused: false,
    refreshDebounce: null
  }),
  actions: {
    setEvent(eventId) {
      this.id = eventId
      this.pageDetails = { page: 0 }
      this.game = {}
      this.sets = []
      this.cancelAutoRefresh()
      this.refreshInterval = null
      this.refreshing = false
      this.refreshCounter = 0
      this.refreshPaused = false
      this.refreshDebounce = null
      this.eventDetails = {}
      if (eventId) {
        eventApi.getEventDetails(eventId).then((res) => {
          this.eventDetails = res.data.data.event
          this.fetchGame()
        })
      }
    },
    resetEvent() {
      this.pageDetails = { page: 0 }
      this.sets = []
    },
    fetchNextPage() {
      if (this.pageDetails.page && this.pageDetails.page >= this.pageDetails.totalPages) {
        return this.props?.done('empty')
      }
      if (!this.refreshInterval) {
        this.refreshInterval = setInterval(() => {
          this.checkRefresh()
        }, 1000)
      }
      this.loading = true
      let tempEventId = this.id
      eventApi.getEventSets(this.id, this.setFilters, this.pageDetails.page + 1, 12).then((res) => {
        if (this.id != tempEventId) {
          return
        }
        res.data.data.event.sets.nodes.forEach((set) => {
          this.upsertSet(set)
        })
        this.pageDetails = res.data.data.event.sets.pageInfo
        this.loading = false
        this.sortSets()
        this.props?.done('ok')
      })
    },
    async checkRefresh() {
      this.refreshCounter++
      if (this.refreshPaused || this.refreshing || this.refreshSeconds[0] == -1) {
        return
      }
      if (this.refreshCounter >= this.refreshSeconds[0]) {
        this.refreshCounter = 0
        await this.refreshSets()
      }
    },
    fetchGame() {
      gameApi.getGame(this.eventDetails.videogame.id).then((res) => {
        this.game = res.data.data.videogame
        if (res.data.data.videogame.characters != null) {
          let loadedCharacters = []
          res.data.data.videogame.characters?.forEach((character) => {
            let img = new Image()
            img.src = character.images.filter((image) => image.type == 'stockIcon')[0]?.url
            loadedCharacters.push({ id: character.id, name: character.name, img })
            loadedCharacters.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
          })
          res.data.data.videogame.characters = loadedCharacters
        }
        this.game = res.data.data.videogame
      })
    },
    cancelAutoRefresh() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval)
        this.refreshInterval = null
      }
    },
    upsertSet(newSet) {
      if (newSet.event?.id != this.id) {
        return
      }
      if (newSet.slots[0].prereqType == 'bye' || newSet.slots[1].prereqType == 'bye') {
        return
      }
      let index = this.sets.findIndex((set) => set.id == newSet.id)
      if (index == -1) {
        this.sets.push(newSet)
      } else {
        this.sets[index] = newSet
      }
    },
    sortSets() {
      this.sets.sort((a, b) => {
        return ORDER[a.state - 1] - ORDER[b.state - 1]
      })
    },
    async refreshSets(hard) {
      this.refreshing = true
      let res
      if (hard) {
        this.sets = []
      }
      let tempId = this.id
      for (let i = 0; i < this.pageDetails.page; i++) {
        try {
          res = await eventApi.getEventSets(this.id, this.setFilters, i + 1, 12)
        } catch (err) {
          console.log(err)
          continue
        }
        if (tempId != this.id) {
          return
        }
        res.data?.data?.event.sets.nodes.forEach((set) => {
          this.upsertSet(set)
        })
      }
      if (res) {
        this.pageDetails = res.data?.data?.event.sets.pageInfo
        if (this.pageDetails.page && this.pageDetails.page < this.pageDetails.totalPages) {
          this.props?.done('ok')
        }
      }
      this.sortSets()
      this.refreshing = false
      this.refreshCounter = 0
    },
    pauseRefresh() {
      this.refreshPaused = true
      if (!this.refreshDebounce) {
        this.refreshDebounce = debounce(() => {
          this.refreshPaused = false
        }, 10000)
      }
      this.refreshDebounce()
    },
    unpauseRefresh() {
      this.refreshPaused = false
    },
    async refreshEvent() {
      let res = await eventApi.getEventDetails(this.id)
      this.eventDetails = res.data.data.event
    },
    deleteSet(id) {
      let index = this.sets.findIndex((set) => set.id == id)
      this.sets.splice(index, 1)
      return index
    },
    async upgradePreview(id) {
      let res = await setApi.upgradePreview(id)
      if (res.data?.errors) {
        throw new Error(JSON.stringify(res.data.errors))
      }
      let upgradedSets = res.data.data.reportBracketSet
      let deletedSets = res.data.actionRecords.delete.sets

      deletedSets.forEach((deletedSet, index) => {
        let replace = this.sets.findIndex((set) => set.id == deletedSet)
        this.sets[replace] = upgradedSets[index]
      })

      return upgradedSets[deletedSets.findIndex((set) => set == id)].id
    }
  }
})
