<template>
  <v-row>
    <v-col
      v-for="tournament in tournaments"
      :key="tournament.id"
      cols="12"
      sm="12"
      md="6"
      lg="4"
      xl="3"
    >
      <tournament-card-mobile
        v-if="smAndDown"
        class="card"
        :use-detected-color="useDetectedColor"
        :tournament="tournament"
      />
      <tournament-card-desktop
        v-else
        class="card"
        :use-detected-color="useDetectedColor"
        :tournament="tournament"
      />
    </v-col>
  </v-row>
</template>
<script>
import { useDisplay } from 'vuetify'
import TournamentCardDesktop from './TournamentCardDesktop.vue'
import TournamentCardMobile from './TournamentCardMobile.vue'

export default {
  components: { TournamentCardDesktop, TournamentCardMobile },
  props: {
    tournaments: {
      type: Array,
      required: true
    },
    useDetectedColor: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  setup() {
    let { smAndDown } = useDisplay()

    return { smAndDown }
  }
}
</script>
<style>
.card:hover {
  cursor: pointer;
}
</style>
