import { usePlausible as useOldPlausible } from 'v-plausible/vue'

export function usePlausible() {
  let { trackEvent, trackPageView } = useOldPlausible()

  const optedInStatus =
    localStorage.getItem('optIn') === null ? true : localStorage.getItem('optIn') === 'true'

  function newTrackEvent(name, options) {
    if (optedInStatus) {
      trackEvent(name, options)
    }
  }

  function newTrackPageView(name) {
    if (optedInStatus) {
      trackPageView(name)
    }
  }

  return { trackEvent: newTrackEvent, trackPageView: newTrackPageView }
}
