<template>
  <div class="d-flex flex-column align-center">
    <StartKitLogo />
    <div class="text-h4 text-center mb-16">The comfiest start.gg helper</div>
  </div>
  <div class="mt-16">
    <v-row class="align-center ml-1">
      <router-link
        :to="{ name: 'tournaments' }"
        class="pl-2 text-h5 cursor-pointer font-weight-medium text-decoration-none"
        variant="plain"
        style="font-family: Audiowide !important; color: inherit"
      >
        My Tournaments<v-icon>mdi-chevron-right</v-icon>
      </router-link>
      <v-switch
        v-model="admin"
        label="Show Admin"
        hide-details
        color="accent"
        @update:model-value="getTournaments"
      ></v-switch>
    </v-row>
    <div v-if="currUserStore.tournaments.length" class="d-flex flex-wrap mt-4">
      <v-col
        v-for="tournament in lg ? currUserStore.tournaments.slice(0, 3) : currUserStore.tournaments"
        :key="tournament.id"
        :cols="smAndDown ? 12 : colsMap[name][currUserStore.tournaments?.length]"
      >
        <tournament-card-mobile
          v-if="smAndDown"
          class="card"
          use-detected-color
          :tournament="tournament"
        />
        <tournament-card-desktop v-else class="card" use-detected-color :tournament="tournament" />
      </v-col>
    </div>
    <div v-else-if="currUserStore.loading" class="d-flex flex-wrap mt-4">
      <v-col v-for="i in 2" :key="i" :cols="smAndDown ? 12 : colsMap[name][2]">
        <v-skeleton-loader
          v-if="!smAndDown"
          loading
          type="image, heading, text@2"
        ></v-skeleton-loader>
        <v-skeleton-loader v-else type="list-item-avatar-three-line"></v-skeleton-loader>
      </v-col>
    </div>
    <div v-else class="d-flex flex-wrap mt-4">
      <h2>No Tournaments here...</h2>
    </div>
  </div>
</template>
<script>
import { useCurrUserStore } from '../stores/currentUser'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import StartKitLogo from '../components/StartKitLogo.vue'
import TournamentCardDesktop from '../components/TournamentCardDesktop.vue'
import TournamentCardMobile from '../components/TournamentCardMobile.vue'

export default {
  components: { StartKitLogo, TournamentCardDesktop, TournamentCardMobile },
  setup() {
    let currUserStore = useCurrUserStore()
    let { lg, name, smAndDown } = useDisplay()

    return { currUserStore, lg, name, smAndDown }
  },
  data() {
    return {
      admin: true,
      colsMap: {
        xxl: {
          1: 6,
          2: 6,
          3: 4,
          4: 3
        },
        xl: {
          1: 6,
          2: 6,
          3: 4,
          4: 3
        },
        lg: {
          1: 6,
          2: 6,
          3: 4,
          4: 4
        },
        md: {
          1: 6,
          2: 6,
          3: 6,
          4: 6
        }
      }
    }
  },
  mounted() {
    this.getTournaments()
  },
  methods: {
    getTournaments() {
      this.currUserStore.init(
        { upcoming: true, tournamentView: this.admin ? 'admin' : null },
        4,
        'tournament.startAt asc'
      )
      this.currUserStore.fetchTournaments()
    }
  }
}
</script>
<style></style>
