import { createRouter, createWebHistory } from 'vue-router'
import ReportingView from '../views/ReportingView.vue'
import TournamentsView from '../views/TournamentsView.vue'
import AboutView from '../views/AboutView.vue'
import HomeView from '../views/HomeView.vue'
import OAuthView from '../views/OAuthView.vue'
import EventView from '../views/EventView.vue'
import SeedingView from '../views/SeedingView.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    { path: '/', component: HomeView, name: 'home' },
    { path: '/about', component: AboutView, name: 'about' },
    {
      path: '/tournament/:tournamentId?/:eventId?/seeding/:phaseId?',
      component: SeedingView,
      name: 'seeding'
    },
    {
      path: '/tournament/:tournamentId?/:eventId?/reporting',
      component: ReportingView,
      name: 'reporting'
    },
    { path: '/tournament/:tournamentId?/', component: EventView, name: 'event' },
    { path: '/tournaments/', component: TournamentsView, name: 'tournaments' },
    { path: '/oauth', component: OAuthView, name: 'oauth' },
    { path: '/privacy-policy', component: PrivacyPolicy, name: 'privacy' }
  ]
})

export default router
