import axios from './axios.js'

export default {
  getEventDetails(eventId) {
    let query = `
        query eventDetails($eventId: ID!) {
            event(id: $eventId) {
              id
              name
              state
              slug
              phases {
                id
                name
                phaseGroups {
                  nodes {
                    id
                    displayIdentifier
                  }
                }
              }
              videogame {
                id
              }
            }
          }
        `
    let variables = { eventId }

    return axios.post('', { query, variables })
  },
  getEventSets(eventId, setFilters, page, perPage) {
    let query = `
      query eventSets($eventId: ID!, $setFilters: SetFilters, $page: Int, $perPage: Int) {
        event(id: $eventId) {
          sets(
            page: $page
            perPage: $perPage
            filters: $setFilters){
            pageInfo {
              totalPages
              page
            }
            nodes {
              id
              winnerId
              state
              event {
                id
              }
              games {
                id
                orderNum
                selections {
                  id
                  character {
                    id
                  }
                  entrant {
                    id
                  }
                }
                stage {
                  id
                  name
                }
                winnerId
              }
              startedAt
              fullRoundText
              identifier
              resetAffectedData {
                affectedSetCount
              }
              phaseGroup {
                id
                displayIdentifier
                phase {
                  id
                  name
                }
              }
              slots {
                id
                standing {
                  placement
                  stats {
                    score {
                      value
                    }
                  }
                }
                prereqPlacement
                prereqType
                entrant {
                  id
                  name
                }
              }
            }
          }
        }
      }`

    let variables = { eventId, setFilters, page, perPage }

    return axios.post('', { query, variables })
  },
  getAttendees(eventId, search) {
    let query = `
      query eventAttendees($eventId: ID!, $search: String) {
        event(id: $eventId) {
          entrants(query: {filter: { name: $search }}) {
            nodes {
              id
            }
          }
        }
      }
    `

    let variables = { eventId, search }

    return axios.post('', { query, variables })
  }
}
