import axios from './axios.js'

export default {
  getPhaseSeeds(phaseId, page) {
    let query = `
    query getPhaseSeeds($phaseId: ID!, $page: Int) {
      phase(id: $phaseId) {
        seeds(query: {sortBy: "seedNum", perPage: 50, page: $page}) {
          pageInfo {
            totalPages
            page
          }
          nodes {
            id
            placeholderName
            entrant {
              name
            }
            phaseGroup {
              id
            }
            seedNum
          }
        }
      }
    }
    
          
        `
    let variables = { phaseId, page }

    return axios.post('', { query, variables })
  },
  getPhaseGroups(phaseId) {
    let query = `
        query getPhaseGroups($phaseId: ID!) {
            phase(id: $phaseId) {
                phaseGroups {
                    nodes {
                        id
                        displayIdentifier
                        wave {
                          id
                          identifier
                        }
                    }
                }
            }
        }  
        `
    let variables = { phaseId }

    return axios.post('', { query, variables })
  },
  updatePhaseSeeding(phaseId, seedMapping) {
    let query = `
        mutation updatePhaseSeeding($phaseId: ID!, $seedMapping: [UpdatePhaseSeedInfo]!) {
          updatePhaseSeeding(phaseId: $phaseId, seedMapping: $seedMapping, options: {strictMode: false}) {
            id
          }
        }
    `

    let variables = { phaseId, seedMapping }

    return axios.post('', { query, variables })
  }
}
