<template lang="">
  <div>
    <template v-if="showPhaseActions">
      <v-btn
        v-if="seedStore.selected.length >= 2"
        size="large"
        icon
        variant="text"
        @click="deselectAll"
      >
        <v-icon>mdi-select-remove</v-icon>
        <v-tooltip activator="parent" location="bottom">Remove Selection</v-tooltip>
      </v-btn>
      <v-btn
        size="large"
        icon
        variant="text"
        :disabled="!(seedStore.selected.length == 2)"
        @click="seedStore.swapSeeds(seedStore.selected[0], seedStore.selected[1])"
      >
        <v-icon>mdi-swap-horizontal</v-icon>
        <v-tooltip activator="parent" location="bottom">Swap Players</v-tooltip>
      </v-btn>
      <v-btn
        size="large"
        icon
        variant="text"
        :disabled="!seedStore.selected.length"
        @click="seedStore.moveSelectionToTop"
      >
        <v-icon>mdi-arrow-collapse-up</v-icon>
        <v-tooltip activator="parent" location="bottom">Move Selection to Top</v-tooltip>
      </v-btn>
    </template>
    <v-btn variant="text" :disabled="!enableShuffle" icon size="large" @click="shuffle">
      <v-badge
        :content="seedStore.selected.length"
        floating
        :model-value="!(seedStore.selected.length <= 1) && enableShuffle"
        :max="99"
      >
        <v-icon>mdi-shuffle</v-icon>
      </v-badge>
      <v-tooltip activator="parent" location="bottom">
        {{ seedStore.selected.length <= 2 ? 'Shuffle All Seeds' : 'Shuffle Selection' }}
      </v-tooltip>
    </v-btn>
    <v-btn variant="text" :disabled="!seedStore.changed" @click="showResetDialog = true">
      Reset
    </v-btn>
    <v-btn color="accent" :disabled="!seedStore.changed" @click="showSaveDialog = true">
      Save
    </v-btn>
    <v-dialog v-model="showResetDialog" width="400px">
      <v-card>
        <v-card-title>Reset Seeds?</v-card-title>
        <v-card-text>
          Are you sure you want to reset? This will reset all changes you have made.
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn variant="flat" color="accent" @click="resetSeeds">Reset</v-btn>
          <v-btn variant="text" @click="showResetDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSaveDialog" width="400px">
      <v-card>
        <v-card-title>Save Seeding Changes?</v-card-title>
        <v-card-text>
          Saving these changes will re-order the pools/group seeding according to the phase-level
          seeding. Do you want to continue?
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn variant="flat" color="accent" @click="saveSeeds">Save</v-btn>
          <v-btn variant="text" @click="showSaveDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showShuffleDialog" width="400px">
      <v-card>
        <v-card-title>Shuffle All Seeds?</v-card-title>
        <v-card-text> The will shuffle the entire phase, are you sure? </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn variant="flat" color="accent" @click="shuffleSeeds">Shuffle!</v-btn>
          <v-btn variant="text" @click="showShuffleDialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { useSeedStore } from '../stores/seed'
import { usePlausible } from '../composables/plausible'

export default {
  props: {
    showPhaseActions: Boolean
  },
  emits: ['deselect'],
  setup() {
    let seedStore = useSeedStore()
    let { trackEvent } = usePlausible()

    return { seedStore, trackEvent }
  },
  data() {
    return {
      showResetDialog: false,
      showSaveDialog: false,
      showShuffleDialog: false
    }
  },
  computed: {
    enableShuffle() {
      if (this.seedStore.selected.length <= 1) {
        return true
      }

      let sorted = this.seedStore.selected.slice().sort((a, b) => b - a)
      return sorted[0] - sorted[sorted.length - 1] == sorted.length - 1
    }
  },
  methods: {
    resetSeeds() {
      this.showResetDialog = false
      this.seedStore.resetSeeds()
    },
    saveSeeds() {
      this.showSaveDialog = false
      this.trackEvent('phaseSeeded')
      this.seedStore.saveSeeds()
    },
    shuffle() {
      if (this.seedStore.selected.length <= 1) {
        this.showShuffleDialog = true
      } else {
        this.seedStore.shuffleSelection()
      }
    },
    shuffleSeeds() {
      this.seedStore.shuffleAll()
      this.showShuffleDialog = false
    },
    deselectAll() {
      this.$emit('deselect')
    }
  }
}
</script>
<style lang=""></style>
