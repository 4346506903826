<template>
  <div class="d-flex">
    <v-btn
      prepend-icon="mdi-filter"
      rounded
      class="mr-2"
      :color="showFilters ? 'primary' : null"
      @click="showFilters = !showFilters"
    >
      Filters
    </v-btn>
    <v-btn-group id="refresh-split-button" rounded="xl" density="comfortable">
      <v-btn
        min-width="48"
        width="48"
        height="36"
        :loading="eventStore.refreshing"
        @click="eventStore.refreshSets()"
      >
        <v-badge
          dot
          location="bottom end"
          color="accent"
          :model-value="eventStore.refreshSeconds[0] > -1"
        >
          <v-icon icon="mdi-refresh"></v-icon>
        </v-badge>
        <v-tooltip activator="parent" location="bottom">{{
          eventStore.refreshSeconds[0] > -1
            ? `Refreshed ${eventStore.refreshCounter}s ago`
            : `Refresh`
        }}</v-tooltip>
      </v-btn>
      <v-btn id="menu-activator" min-width="12" width="12" height="36">
        <v-icon icon="mdi-chevron-down"></v-icon>
      </v-btn>
      <v-menu activator="#menu-activator" target="#refresh-split-button">
        <v-list v-model:selected="eventStore.refreshSeconds">
          <v-list-item v-for="(item, index) in items" :key="index" :value="item.value">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn-group>
  </div>
  <v-divider class="my-1"></v-divider>
  <v-expand-transition>
    <div v-show="showFilters">
      <div class="mb-2 mr-4">
        <SetFilters :phases="eventStore.eventDetails.phases"></SetFilters>
      </div>
      <v-divider class="my-2"></v-divider>
    </div>
  </v-expand-transition>
  <SetCardCollection v-if="eventStore.id" />
</template>
<script>
import SetCardCollection from '../components/SetCardCollection.vue'
import SetFilters from '../components/SetFilters.vue'
import { useEventStore } from '../stores/event'
import { useTournamentStore } from '../stores/tournament'
import { useSearchStore } from '../stores/search'

export default {
  components: { SetCardCollection, SetFilters },
  setup() {
    let eventStore = useEventStore()
    let searchStore = useSearchStore()
    let tournamentStore = useTournamentStore()
    return { eventStore, tournamentStore, searchStore }
  },
  data: () => {
    return {
      showFilters: false,
      items: [
        {
          title: 'Disable',
          value: -1
        },
        {
          title: '15s',
          value: 15
        },
        {
          title: '30s',
          value: 30
        },
        {
          title: '1m',
          value: 60
        },
        {
          title: '5m',
          value: 300
        }
      ]
    }
  },
  watch: {
    '$route.params.eventId'() {
      this.eventStore.setEvent(this.$route.params.eventId)
    }
  },
  mounted() {
    this.tournamentId = this.$route.params.tournamentId
    this.tournamentStore.fetchTournamentDetails(this.tournamentId)
    this.eventStore.setEvent(this.$route.params.eventId)
  }
}
</script>
