<template>
  <div class="d-flex flex-column overflow-hidden">
    <v-text-field
      v-model="search"
      variant="underlined"
      prepend-inner-icon="mdi-magnify"
      hide-details
      label="Search"
      class="ma-2"
    ></v-text-field>
    <div class="overflow-y-auto">
      <button
        v-for="character in filtered"
        :key="character.id"
        class="charButton"
        @click="$emit('select', character)"
      >
        <img :src="character.img.src" style="width: 32px; height: 32px" />
      </button>
    </div>
  </div>
</template>
<script>
import { useEventStore } from '../stores/event'

export default {
  emits: ['select'],
  setup() {
    let eventStore = useEventStore()

    return { eventStore }
  },
  data() {
    return {
      search: '',
      characters: []
    }
  },
  computed: {
    filtered() {
      return this.characters.filter((character) =>
        character.name
          .normalize('NFD')
          .replace(/[^a-z]/gi, '')
          .toLowerCase()
          .includes(this.search.toLowerCase())
      )
    }
  },
  mounted() {
    this.characters = this.eventStore.game.characters
  }
}
</script>
<style>
.charButton {
  width: 36px;
  height: 36px;
  padding: 2px;
  border-radius: 4px;
}

.charButton:hover {
  background-color: rgba(211, 211, 211, 0.2);
}
</style>
