import { defineStore } from 'pinia'
import currUser from '../api/currentUser'

export const useCurrUserStore = defineStore('currUser', {
  state: () => ({
    tournaments: [],
    pageNo: 0,
    totalPages: null,
    pageSize: 0,
    filter: {},
    sortBy: null,
    details: null,
    loading: false,
    error: false
  }),
  actions: {
    init(filter, pageSize, sortBy) {
      this.filter = filter
      this.pageSize = pageSize
      this.sortBy = sortBy
      this.pageNo = 0
      this.totalPages = null
      this.tournaments = []
    },
    fetchTournaments(props) {
      if (this.pageNo === this.totalPages) {
        return props?.done('empty')
      }
      this.pageNo++
      this.loading = true
      currUser
        .getTournamentsForCurrentUser(this.pageNo, this.pageSize, this.filter, this.sortBy)
        .then((res) => {
          this.tournaments = this.tournaments.concat(res.data.data.currentUser.tournaments.nodes)
          this.totalPages = res.data.data.currentUser.tournaments.pageInfo.totalPages
          if (props) {
            if (this.totalPages == this.pageNo) {
              props?.done('empty')
            } else {
              props?.done('ok')
            }
          }
        })
        .catch(() => {
          props?.done('error')
        })
        .finally(() => {
          this.loading = false
        })
    },
    fetchDetails() {
      currUser.getCurrentUserDetails().then((res) => {
        this.details = res.data.data.currentUser
      })
    }
  }
})
