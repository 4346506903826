<template lang="">
  <div>
    <h1>Privacy Policy for startkit.gg</h1>

    <h2>Introduction</h2>
    <br />
    <p>
      StartKit.gg is dedicated to protecting the privacy of our users. This Privacy Policy outlines
      our practices concerning the collection, use, and sharing of non-personal and anonymized data
      through our website. Our goal is to provide a secure and mobile-friendly interface for
      accessing data provided by start.gg, ensuring compliance with the General Data Protection
      Regulation (GDPR).
    </p>
    <br />
    <h2>Data Collection and Use</h2>
    <br />
    <p>
      We use Plausible.io to collect non-personal analytics data to understand how our users
      interact with our website. This data helps us identify user flows within each session and make
      improvements to enhance user experience. We do not collect any personal data through our
      website. You can read Plausable's privacy policy
      <a href="https://plausible.io/privacy">here</a>.
    </p>
    <p>
      We use Sentry.io to collect non-personal error data to help us fix issues with our website
      rapidly. You can read Sentry's privacy policy
      <a href="https://sentry.io/privacy/">here</a>.
    </p>
    <p>
      You may choose to prevent this website from aggregating and analyzing the actions you take
      here. Doing so will protect your privacy, but will also prevent the owner from learning from
      your actions and creating a better experience for you and other users.
    </p>
    <v-checkbox v-model="optIn" :label="labelText"></v-checkbox>
    <br />
    <h2>User Rights</h2>
    <br />
    <p>Under the GDPR, users have several rights concerning their data, including:</p>
    <ul class="ml-8">
      <li>
        <strong>Right to Access:</strong> You have the right to request access to any data related
        to you.
      </li>
      <li>
        <strong>Right to Rectification:</strong> You can ask us to correct any inaccurate data about
        you.
      </li>
      <li>
        <strong>Right to Erasure:</strong> You have the right to request the deletion of your data
        under certain conditions.
      </li>
      <li>
        <strong>Right to Restriction of Processing:</strong> You can request that we limit the
        processing of your data.
      </li>
      <li>
        <strong>Right to Data Portability:</strong> You have the right to receive your data in a
        structured, commonly used format.
      </li>
      <li><strong>Right to Object:</strong> You can object to the processing of your data.</li>
      <li>
        <strong>Rights in Relation to Automated Decision Making and Profiling:</strong> You have the
        right not to be subject to decisions based solely on automated processing.
      </li>
    </ul>
    <br />
    <h2>Data Protection</h2>
    <br />
    <p>
      We are committed to protecting your data. The analytics data collected is anonymized and
      stored securely. It is retained for a period of 365 days before being automatically deleted.
    </p>
    <br />
    <h2>Contact Information</h2>
    <br />
    <p>
      If you have any questions about this privacy policy or your rights under the GDPR, please
      contact us at <a href="mailto:admin@startkit.gg">admin@startkit.gg</a>.
    </p>
    <br />
    <h2>Policy Updates</h2>
    <br />
    <p>
      We may update this privacy policy from time to time. Users will be notified of any significant
      changes through our website or via direct communication.
    </p>
  </div>
</template>
<script>
export default {
  data() {
    return {
      optIn:
        localStorage.getItem('optIn') === null ? true : localStorage.getItem('optIn') === 'true'
    }
  },
  computed: {
    labelText() {
      return this.optIn
        ? 'You are not opted out. Uncheck this box to opt-out.'
        : 'You are currently opted out. Check this box to opt-in.'
    }
  },
  watch: {
    optIn() {
      localStorage.setItem('optIn', this.optIn)
    }
  }
}
</script>
<style lang=""></style>
