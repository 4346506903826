<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <v-infinite-scroll height="100%" width="100%" :onLoad="loadSets" style="overflow: hidden">
    <template #default>
      <v-row v-if="filteredSets">
        <v-col
          v-for="(column, index) in masonry(filteredSets)"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <SetCard v-for="set in column" :key="set.id" :set="set" class="mb-4" />
        </v-col>
      </v-row>
      <v-row v-show="!filteredSets">
        <v-col
          v-for="(column, index) in masonry(eventStore.sets)"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <SetCard v-for="set in column" :key="set.id" :set="set" class="mb-4" />
        </v-col>
      </v-row>
    </template>
    <template #empty>
      <div class="align-center d-flex flex-column">
        <div class="text-h4">No more results</div>
        <!-- <v-img width="256" height="256" src="/img/sleeping.png"></v-img> -->
      </div>
    </template>
    <template #loading>
      <div class="align-center d-flex flex-column" style="width: 100%">
        <v-row style="width: 100%">
          <v-col v-for="col in colMap[name]" :key="col" cols="12" sm="6" md="4" lg="3" xl="2">
            <v-skeleton-loader loading type="text@5, button@4"></v-skeleton-loader>
          </v-col>
        </v-row>
        <!-- <v-img width="256" height="256" src="/img/searching.png"></v-img> -->
      </div>
    </template>
  </v-infinite-scroll>
</template>
<script>
import SetCard from './SetCard.vue'
import { useDisplay } from 'vuetify'
import { useEventStore } from '../stores/event'
import { useSearchStore } from '../stores/search'
import { debounce } from 'lodash'
import eventApi from '../api/event'

export default {
  components: { SetCard },
  setup() {
    let { name } = useDisplay()
    let eventStore = useEventStore()
    let searchStore = useSearchStore()

    return { name, eventStore, searchStore }
  },
  data() {
    return {
      colMap: {
        xs: 1,
        sm: 2,
        md: 3,
        lg: 4,
        xl: 6,
        xxl: 6
      },
      search: debounce(this.searchSets, 500),
      filteredSets: null
    }
  },
  watch: {
    'eventStore.setFilters': {
      deep: true,
      handler() {
        this.eventStore.props?.done('ok')
        this.eventStore.refreshSets(!this.eventStore.setFilters.entrantIds.length)
      }
    },
    'eventStore.sets': {
      deep: true,
      handler() {
        if (this.filteredSets) {
          this.filterSets()
        }
      }
    }
  },
  mounted() {
    this.currentEvent = this.eventStore.id
    this.eventStore.$subscribe(() => {
      if (this.currentEvent !== this.eventStore.id) {
        this.currentEvent = this.eventStore.id
        this.eventStore.props?.done('ok')
      }
    })

    this.searchStore.$subscribe(() => {
      this.search()
    })
  },
  methods: {
    loadSets(props) {
      this.eventStore.props = props
      this.eventStore.fetchNextPage(props)
    },
    masonry(sets) {
      let result = [...Array(this.colMap[this.name]).keys()].map((c) =>
        sets.filter((_, i) => i % this.colMap[this.name] === c)
      )
      return result
    },
    searchSets() {
      if (!this.searchStore.value) {
        this.filteredSets = null
        this.eventStore.setFilters.entrantIds = []
        return
      }

      this.filterSets()

      eventApi.getAttendees(this.eventStore.id, this.searchStore.value).then((res) => {
        let entrantIds = []
        res.data.data.event.entrants.nodes.forEach((entrant) => {
          entrantIds.push(entrant.id)
        })
        this.eventStore.setFilters.entrantIds = entrantIds
      })
    },
    filterSets() {
      this.filteredSets = this.eventStore.sets.filter(
        (set) =>
          set.slots[0].entrant?.name.toLowerCase().includes(this.searchStore.value.toLowerCase()) ||
          set.slots[1].entrant?.name.toLowerCase().includes(this.searchStore.value.toLowerCase())
      )
    }
  }
}
</script>
<style></style>
