import axios from './axios.js'

export default {
  getGame(gameId) {
    let query = `
        query game($gameId: ID!) {
            videogame(id: $gameId) {
              id
              displayName
              name
              images {
                id
                type
                url
              }
              stages {
                id
                name
              }
              characters {
                id
                name
                images {
                  id
                  type
                  url
                }
              }
            }
          }
        `
    let variables = { gameId }

    return axios.post('', { query, variables })
  }
}
