import axios from 'axios'
import { useNotificationStore } from '../stores/notification'

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

let accessToken = import.meta.env.DEV ? import.meta.env.VITE_API_KEY : getCookie('access_token')

let instance = axios.create({
  baseURL: 'https://api.start.gg/gql/alpha',
  headers: { Authorization: `Bearer ${accessToken}` },
  timeout: 3000
})

instance.interceptors.response.use(
  (res) => {
    if (Object.keys(res.data).includes('errors')) {
      setTimeout(() => {
        let notification = useNotificationStore()
        if (!notification.visible) {
          notification.showNotification(
            'Something went wrong: ' + res.data.errors[0].message,
            'error'
          )
        }
      }, 200)
    }
    return res
  },

  (err) => {
    setTimeout(() => {
      let notification = useNotificationStore()
      if (!notification.visible) {
        notification.showNotification('Something went wrong: ' + err.code, 'error')
      }
    }, 200)
    if (err.code === 'ECONNABORTED') {
      let notification = useNotificationStore()
      notification.showNotification('Request to start.gg timed out D: Try refreshing!', 'error')
    }
    if (err.response?.status == 401) {
      if (accessToken) {
        window.open(`/oauth?token=${accessToken}`, '_self')
      }
    }
    return err
  }
)

export default instance
