<template lang="">
  <div class="d-flex flex-column" style="margin-bottom: 128px">
    <div v-for="(phaseGroups, wave) in seedStore.phaseGroups" :key="wave">
      {{ seedStore.waves[wave] ? `Wave ${seedStore.waves[wave]}` : 'No wave assigned' }}
      <v-divider></v-divider>
      <div
        class="d-flex overflow-auto"
        :class="{
          'flex-wrap': !smAndDown,
          'scroll-snap-container': platform.touch && smAndDown && !disableScrollSnap
        }"
      >
        <v-card
          v-for="phaseGroup in phaseGroups"
          :key="`${phaseGroup.id}-${refresh[phaseGroup.id]}`"
          class="ma-1 pa-0 pt-1 z-auto scroll-snap-item"
          width="220px"
          height="100%"
          variant="flat"
        >
          <div class="w-100 text-center">{{ `Pool ${phaseGroup.displayIdentifier}` }}</div>
          <v-skeleton-loader v-if="seedStore.loading" type="list-item@6"></v-skeleton-loader>
          <SortableList
            v-else
            :phase-id="phaseGroup.id"
            :seed-list="seedStore.phaseGroupSeedsRaw[phaseGroup.id]"
            :list="createPhaseGroup(phaseGroup.id)"
            @start="startMove()"
            @update-seed="onUpdate"
          ></SortableList>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
import { useSeedStore } from '../stores/seed'
import { useDisplay } from 'vuetify/lib/framework.mjs'
import SortableList from '../components/SortableList.vue'

export default {
  components: { SortableList },
  setup() {
    let seedStore = useSeedStore()
    let { smAndDown, platform } = useDisplay()

    return { seedStore, smAndDown, platform }
  },
  data: function () {
    return {
      refresh: {},
      disableScrollSnap: false
    }
  },
  methods: {
    startMove() {
      this.disableScrollSnap = true
    },
    createPhaseGroup(phaseGroupId) {
      let phaseGroup = []

      this.seedStore.phaseGroupSeedsRaw[phaseGroupId]?.forEach((seedNum) => {
        phaseGroup.push(this.seedStore.seeds[seedNum - 1])
      })
      return phaseGroup
    },
    forceRefresh(toPhaseGroup, fromPhaseGroup) {
      if (this.refresh[toPhaseGroup]) {
        this.refresh[toPhaseGroup] += 1
      } else {
        this.refresh[toPhaseGroup] = 1
      }
      if (toPhaseGroup != fromPhaseGroup) {
        if (this.refresh[fromPhaseGroup]) {
          this.refresh[fromPhaseGroup] += 1
        } else {
          this.refresh[fromPhaseGroup] = 1
        }
      }
    },
    onUpdate({ fromPhaseGroup, toPhaseGroup, oldIndex, newIndex }) {
      this.disableScrollSnap = false
      if (!fromPhaseGroup || (oldIndex == newIndex && fromPhaseGroup == toPhaseGroup)) {
        return
      }
      if (fromPhaseGroup == toPhaseGroup) {
        this.seedStore.moveSeedTo(
          this.seedStore.phaseGroupSeedsRaw[fromPhaseGroup][oldIndex],
          this.seedStore.phaseGroupSeedsRaw[fromPhaseGroup][newIndex]
        )
        if (oldIndex > newIndex) {
          for (let i = newIndex; i < oldIndex; i++) {
            this.seedStore.moveSeedTo(
              this.seedStore.phaseGroupSeedsRaw[fromPhaseGroup][i] + 1,
              this.seedStore.phaseGroupSeedsRaw[fromPhaseGroup][i + 1]
            )
          }
        } else {
          for (let i = oldIndex + 1; i <= newIndex; i++) {
            this.seedStore.moveSeedTo(
              this.seedStore.phaseGroupSeedsRaw[fromPhaseGroup][i] - 1,
              this.seedStore.phaseGroupSeedsRaw[fromPhaseGroup][i - 1]
            )
          }
        }
      } else {
        this.seedStore.swapSeeds(
          this.seedStore.phaseGroupSeedsRaw[fromPhaseGroup][oldIndex],
          this.seedStore.phaseGroupSeedsRaw[toPhaseGroup][newIndex]
        )
      }
      this.$nextTick(() => {
        this.forceRefresh(toPhaseGroup, fromPhaseGroup)
      })
    }
  }
}
</script>
<style>
.z-auto {
  z-index: auto;
}

.scroll-snap-container {
  scroll-snap-type: both mandatory;
  overscroll-behavior-x: contain;
}

.scroll-snap-item {
  scroll-snap-align: center;
  scroll-snap-stop: always;
}
</style>
