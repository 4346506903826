import axios from './axios'

export default {
  getEventsFromTournament(tournamentId) {
    let query = `
        query tournament($tournamentId: ID!) {
            tournament(id: $tournamentId) {
              id
              name
              images {
                url
                type
              }
              events {
                id
                type
                name
                state
                startAt
                phases {
                  id
                  name
                }
                videogame {
                  id
                  name
                  images {
                    url
                    type
                    width
                    height
                  }
                }
              }
            }
        }`

    let variables = { tournamentId }

    return axios.post('', { query, variables })
  }
}
