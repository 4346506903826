<template>
  <div v-if="tournamentStore.tournament" class="d-flex flex-wrap">
    <v-col v-for="event in tournamentStore.tournament?.events" :key="event.id" cols="12" lg="6">
      <EventCard :event="event" :tournament-id="tournamentStore.tournament.id"></EventCard>
    </v-col>
  </div>
  <div v-else class="d-flex flex-wrap">
    <v-col v-for="i in mdAndDown ? 1 : 2" :key="i" cols="12" lg="6">
      <v-skeleton-loader :key="i" type="list-item-avatar-three-line, button@2"> </v-skeleton-loader>
    </v-col>
  </div>
</template>
<script>
import { useDisplay } from 'vuetify/lib/framework.mjs'
import EventCard from '../components/EventCard.vue'
import { useTournamentStore } from '../stores/tournament'
export default {
  components: { EventCard },
  setup() {
    let { mdAndDown } = useDisplay()
    let tournamentStore = useTournamentStore()
    return { tournamentStore, mdAndDown }
  },
  mounted() {
    this.tournamentId = this.$route.params.tournamentId
    this.tournamentStore.fetchTournamentDetails(this.tournamentId)
  }
}
</script>
