<template lang="">
  <v-card>
    <v-btn-toggle v-model="darkLight" mandatory class="w-100">
      <v-btn prepend-icon="mdi-weather-sunny" class="w-50" @click="updateTheme()">Light</v-btn>
      <v-btn prepend-icon="mdi-weather-night" class="w-50" @click="updateTheme()">Dark</v-btn>
    </v-btn-toggle>
    <v-list v-model:selected="themeName" mandatory @update:selected="(selected) => updateTheme()">
      <v-list-item
        v-for="theme in currThemes"
        :key="theme"
        :value="themes[theme].name"
        :title="themes[theme].name"
      >
        <template #append>
          <div class="d-flex ml-2">
            <div class="circle mx-1" :style="{ background: themes[theme].colors.primary }"></div>
            <div class="circle mx-1" :style="{ background: themes[theme].colors.secondary }"></div>
            <div class="circle mx-1" :style="{ background: themes[theme].colors.accent }"></div>
          </div>
        </template>
      </v-list-item>
    </v-list>
  </v-card>
</template>
<script>
import { useTheme } from 'vuetify/lib/framework.mjs'

export default {
  setup() {
    let { themes, global } = useTheme()
    return { themes, global }
  },
  data() {
    return {
      darkLight: 0,
      themeName: []
    }
  },
  computed: {
    currThemes() {
      return Object.keys(this.themes).filter(
        (theme) => this.themes[theme].dark == this.darkLight && this.themes[theme].name
      )
    }
  },
  mounted() {
    this.darkLight = this.global.current.value.dark ? 1 : 0
    this.themeName = [this.global.current.value.name]
  },
  methods: {
    updateTheme() {
      this.global.name.value = Object.keys(this.themes).filter(
        (theme) =>
          this.themes[theme].name == this.themeName[0] && this.themes[theme].dark == this.darkLight
      )[0]

      localStorage.setItem('theme', this.global.name._rawValue)
    }
  }
}
</script>
<style>
.circle {
  width: 15px;
  height: 15px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
</style>
