<template>
  <div class="d-flex flex-column align-center">
    <StartKitLogo />
    <div class="text-h4 text-center mb-2">The comfiest start.gg helper</div>
    <v-hover v-slot="{ isHovering, props }">
      <v-btn
        :disabled="!oauthHref"
        :loading="!oauthHref"
        v-bind="props"
        width="300px"
        :elevation="isHovering ? 24 : 2"
        variant="tonal"
        color="accent"
        size="large"
        :href="oauthHref"
      >
        <template #prepend>
          <v-img src="/img/startgg-logo.png" width="32" height="32"></v-img>
        </template>
        Log In with start.gg
      </v-btn>
    </v-hover>
  </div>
  <div class="d-flex flex-wrap w-100 mt-1 justify-center">
    <v-btn
      variant="outlined"
      color="primary"
      class="mr-1"
      @click="openInNew('https://discord.gg/mzq6PWtARa')"
    >
      <template #prepend>
        <v-img width="20" :src="`/img/discord-${current.dark ? 'white' : 'blue'}.svg`"></v-img>
      </template>
      Join the Discord!
    </v-btn>
    <v-btn
      variant="outlined"
      color="primary"
      class="mr-1"
      @click="openInNew('https://twitter.com/CloakedMk')"
    >
      <template #prepend>
        <v-img width="20" :src="`/img/x-${current.dark ? 'white' : 'black'}.svg`"></v-img>
      </template>
      Follow me!
    </v-btn>
    <v-btn variant="outlined" color="primary" @click="openInNew('https://ko-fi.com/cloaked')">
      <template #prepend>
        <v-img width="20" src="/img/ko-fi.png"></v-img>
      </template>
      Buy me a coffee!
    </v-btn>
  </div>
  <div :class="{ 'd-flex': true, 'flex-wrap': true, 'px-12': lgAndUp }">
    <v-col v-for="info in infoData" :key="info.title" cols="12" sm="12" md="6" lg="4" xl="4">
      <v-card height="200px">
        <v-card-title>
          <div
            class="icon d-flex align-center justify-center text-center text-h4"
            :style="{ 'background-color': info.color }"
          >
            {{ info.icon }}
          </div>
        </v-card-title>
        <v-card-text class="d-flex flex-column justify-space-around" style="height: 136px">
          <div class="text-h4">{{ info.title }}</div>
          <div class="text-h6">{{ info.body }}</div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import { useDisplay, useTheme } from 'vuetify/lib/framework.mjs'
import axios from 'axios'
import StartKitLogo from '../components/StartKitLogo.vue'

export default {
  components: { StartKitLogo },
  setup() {
    let { lgAndUp } = useDisplay()
    let { current } = useTheme()

    return { lgAndUp, current }
  },
  data: function () {
    return {
      oauthHref: null,
      infoData: [
        {
          icon: '⚡',
          color: 'rgba(255, 235, 59, 0.4)',
          title: 'Lightning Fast',
          body: "We won't slow you down while you manage your tournament"
        },
        {
          icon: '📱',
          color: 'rgba(33, 150, 243, 0.4)',
          title: 'Optimised for all devices',
          body: "No matter what device you use, we're here for you"
        },
        {
          icon: '👍',
          color: 'rgba(255, 193, 7, 0.4)',
          title: 'Easy Setup',
          body: 'With start.gg OAuth, you are just one click away'
        },
        {
          icon: '⚙️',
          color: 'rgba(96, 125, 139, 0.4)',
          title: 'Constantly Updated',
          body: "We've always got new features in the works"
        },
        {
          icon: '🏡',
          color: 'rgba(244, 67, 54, 0.4)',
          title: 'Familiar Feel',
          body: 'Feels like start.gg, just with a different coat of paint'
        },
        {
          icon: '💬',
          color: 'rgba(245, 245, 245, 0.4)',
          title: 'Community Driven',
          body: "We're always looking for ways to help you, give us feedback!"
        }
      ]
    }
  },
  mounted() {
    axios.get('/oauth_href').then((res) => {
      this.oauthHref = res.data
    })
  },
  methods: {
    openInNew(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
<style>
.icon {
  height: 48px;
  width: 48px;
  border-radius: 8px;
}
</style>
